import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import './assets/css/global.css'

import axios from 'axios'
import dayjs from 'dayjs'
// Vue.filter('dateFormat',val=>{
//   return dayjs(val).format('YYYY-MM-DD HH:mm:ss')
// })
Vue.prototype.dayjs = dayjs
//请求根路径
// axios.defaults.baseURL = 'http://localhost:8080'
axios.defaults.baseURL = 'https://www.rongtuhb.com:8080/'
Vue.prototype.$http = axios
//在每一次请求时，会在请求头中使用 Authorization 字段提供 token 令牌
axios.interceptors.request.use(config => {
  // config.headers.Authorization = window.sessionStorage.getItem('token') || ''
  config.headers.Token = window.sessionStorage.getItem('token')||''

  return config
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')