<template>
  <div
    class="page_container"
    v-loading="loading"
    element-loading-text=""
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <el-drawer
      title="异常数据"
      :visible.sync="errorDataDrawer"
      size="1200px"
      direction="ltr"
    >
      <el-tabs
        v-model="processErrorTab"
        type="card"
        @tab-click="changeProcessErrorTab"
      >
        <el-tab-pane
          :label="this.toProcessArr.length + ' 个未处理异常'"
          name="toProcessError"
        >
          <div v-if="toProcessArr.length > 0">
            <div style="display: flex">
              <div class="error-panel-item">
                公 司：{{ toProcessArr[currentErrorPage_1 - 1].companyName }}
              </div>
              <div class="error-panel-item">
                联系人：{{
                  toProcessArr[currentErrorPage_1 - 1].companyContact
                }}
              </div>
              <div class="error-panel-item">
                手 机：{{ toProcessArr[currentErrorPage_1 - 1].companyPhone }}
              </div>
            </div>

            <div class="error-panel-item" style="font-size: 0.8em">
              注：下方流量计数据项中，每一栏对应一个流量计，各栏中第1项为“进入异常状态”前最后记录的流量计正常值、第2项为“进入异常状态”时的流量计值。只要其中一个流量计发生异常，则该企业流量监控“进入异常状态”。
            </div>
            <div class="error-table-container">
              <div
                class="error-table"
                v-for="(table, i) in toProcessArr[currentErrorPage_1 - 1]
                  .flowMonitors"
                :key="i + table.errorMsg"
              >
                <div style="margin: 15px 0">
                  {{ i + 1 + ". " + table.errorMsg }}
                </div>
                <div class="record-item" style="color: green">
                  <div>
                    {{ table.normalTime + ": " + table.normalRecord + "吨" }}
                  </div>
                  <div>正常</div>
                </div>
                <div class="record-item">
                  <div>
                    {{
                      table.errorTimes[0] +
                      ": " +
                      table.errorRecordArr[0] +
                      "吨"
                    }}
                  </div>
                  <div
                    :style="{
                      color:
                        table.isNormalArr[0] == '正常' ? '#409eff' : '#F56C6C',
                    }"
                  >
                    {{ table.isNormalArr[0] }}
                  </div>
                </div>
              </div>
            </div>
            <div class="error-panel-item">
              <el-button
                type="danger"
                :disabled="this.loading"
                @click="
                  startProcess(toProcessArr[currentErrorPage_1 - 1].companyId)
                "
                >开始处理</el-button
              >
            </div>
            <div style="display: flex; justify-content: center">
              <el-pagination
                @current-change="currentErrorCompanyChange_1"
                :current-page="currentErrorPage_1"
                :page-size="1"
                layout="total,prev, pager, next, jumper"
                :total="toProcessArr.length"
              >
              </el-pagination>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane
          :label="this.processingArr.length + ' 个正在处理异常'"
          name="processingError"
        >
          <div v-if="processingArr.length > 0">
            <div style="display: flex">
              <div class="error-panel-item">
                公 司：{{ processingArr[currentErrorPage_2 - 1].companyName }}
              </div>
              <div class="error-panel-item">
                联系人：{{
                  processingArr[currentErrorPage_2 - 1].companyContact
                }}
              </div>
              <div class="error-panel-item">
                手 机：{{ processingArr[currentErrorPage_2 - 1].companyPhone }}
              </div>
            </div>

            <div class="error-panel-item" style="font-size: 0.8em">
              注：下方流量计数据项中，每一栏对应一个流量计，各栏中第1项为“进入异常状态”前最后记录的流量计正常值、第2项为“进入异常状态”时的流量计值、3~4项为异常发生后近2个流量计值，后面项为最近10个流量计值、最后1项为异常期间该流量计的总流量，当所有栏的最后1项为“正常”，才能点击“确认正常”按钮。只要其中一个流量计发生异常，则该企业流量监控“进入异常状态”。另外，当某个流量计发生网络异常，在服务器重新收到该流量计发来的数据时，由服务器自动取消网络异常警报，无需在中控界面手动确认正常。
            </div>
            <div class="error-table-container">
              <div
                class="error-table"
                v-for="(table, i) in processingArr[currentErrorPage_2 - 1]
                  .flowMonitors"
                :key="i + table.errorMsg"
              >
                <div style="margin: 15px 0">
                  {{ i + 1 + ". " + table.errorMsg }}
                </div>
                <div class="record-item" style="color: green">
                  <div>
                    {{ table.normalTime + ": " + table.normalRecord + "吨" }}
                  </div>
                  <div>正常</div>
                </div>
                <div
                  class="record-item"
                  v-for="(item, j) in table.errorTimeStamps"
                  :key="item"
                >
                  <div>
                    {{
                      table.errorTimes[j] +
                      ": " +
                      table.errorRecordArr[j] +
                      "吨"
                    }}
                  </div>
                  <div
                    :style="{
                      color:
                        table.isNormalArr[j] == '正常' ? '#409eff' : '#F56C6C',
                    }"
                  >
                    {{ table.isNormalArr[j] }}
                  </div>
                </div>
                <div class="record-item">
                  <div>
                    异常期间总流量：
                    {{
                      table.flowDuringError == "未知"
                        ? "未知"
                        : table.flowDuringError.toFixed(2)
                    }}吨
                  </div>
                  <div
                    :style="{
                      color: table.isNormal == '正常' ? '#409eff' : '#F56C6C',
                    }"
                  >
                    {{ table.isNormal }}
                  </div>
                </div>
              </div>
            </div>
            <div class="error-panel-item">
              <el-button
                type="danger"
                :disabled="
                  processingArr[currentErrorPage_2 - 1].isNormal == '异常' ||
                  this.loading
                "
                @click="
                  cofirmNormal(processingArr[currentErrorPage_2 - 1].companyId)
                "
                >确认正常</el-button
              >
            </div>
            <div style="display: flex; justify-content: center">
              <el-pagination
                @current-change="currentErrorCompanyChange_2"
                :current-page="currentErrorPage_2"
                :page-size="1"
                layout="total,prev, pager, next, jumper"
                :total="processingArr.length"
              >
              </el-pagination>
            </div></div
        ></el-tab-pane>
        <el-tab-pane
          :label="moreThanTatolArr.length + ' 家企业工业补水大于总进水异常'"
          name="moreThanError"
        >
          <div class="error-panel-item" style="font-size: 0.8em">
            注：如果某家公司当天累计的“工业补水减去总自来水进水>总自来水进水乘以0.05”，则被判定为“工业补水大于总进水异常”，产生此类异常的公司将展示在本面板中。
          </div>
          <el-divider></el-divider>
          <div style="display: flex; flex-wrap: wrap; width: 100%">
            <div
              v-for="item in moreThanTatolArr"
              :key="item.companyId"
              style="width: 50%"
            >
              <div class="error-panel-item">公 司：{{ item.companyName }}</div>
              <div class="error-panel-item">
                联系人：{{ item.companyContact }}
              </div>
              <div class="error-panel-item">手 机：{{ item.companyPhone }}</div>
              <div class="error-panel-item">
                今天总自来水进水累计：{{ item.totalTapWater }}吨
              </div>
              <div class="error-panel-item">
                今天工业补水累计：{{ item.totalIndustrialAddWater }}吨
              </div>
              <el-divider></el-divider>
            </div></div
        ></el-tab-pane>
      </el-tabs>
    </el-drawer>
    <div class="home_container">
      <el-card class="search-card">
        <div style="display: flex; justify-content: space-between">
          <div>
            <span class="search-label">按区域及行业查看 </span>
            <el-cascader
              v-if="regionCode == 'JY'"
              filterable
              clearable
              :props="{ checkStrictly: true }"
              v-model="areaCode"
              @change="selecteArea"
              ref="selecteAreaLabelRef"
              :options="selectableArea[regionCode]"
              placeholder="请选择或输入区域"
            ></el-cascader>
            <el-select
              v-else
              v-model="area"
              filterable
              default-first-option
              clearable
              placeholder="请选择或输入区域"
            >
              <!-- allow-create
            @blur="onSelectAreaBlur" -->
              <el-option
                v-for="item in selectableArea[regionCode]"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>

            <el-select
              v-model="companyType"
              filterable
              clearable
              default-first-option
              placeholder="请选择或输入行业类型"
              style="margin: 0 20px"
            >
              <el-option
                v-for="item in selectableCompanyType"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
            <el-button
              type="info"
              class="search-btn"
              icon="el-icon-circle-close"
              @click="clearAreaAndType"
              >清除</el-button
            >
          </div>
          <div>
            <el-button
              :type="errorButtonType"
              icon="el-icon-bell"
              :class="hasErrorData ? 'has-error-data-btn' : ''"
              @click="openErrorDataDrawer"
              >{{ errorButtonText }}</el-button
            >
          </div>
          <div class="company-sarech-area">
            <div class="display: flex;align-items: center;">
              <span class="search-label">按公司名称查看 </span>
            </div>
            <div style="flex-grow: 1; padding-right: 20px">
              <el-input
                placeholder="请输入公司名称关键字"
                v-model="companyKeyword"
                clearable
                style="display: inline-block"
              >
              </el-input>
            </div>
            <div>
              <el-button
                type="info"
                class="search-btn"
                icon="el-icon-circle-close"
                @click="clearKeyword"
                >清除</el-button
              >
            </div>
          </div>

          <!-- 表格区域 -->
        </div>
      </el-card>
      <el-card class="monitor-card">
        <div class="monitor-title">
          <div>企业今日累计用水实时监控</div>
          <div class="date-time">{{ nowTime }}</div>
        </div>

        <el-table
          ref="monitorTable"
          :data="
            companyList.filter(
              (data) =>
                data.companyInTown.includes(area) &&
                data.companyType.includes(companyType) &&
                data.companyName.includes(companyKeyword.trim())
            )
          "
          show-summary
          :header-cell-class-name="tableHeaderClassName"
          :cell-class-name="tableRowClassName"
          max-height="710"
        >
          <el-table-column label="#" type="index" width="70"></el-table-column>
          <el-table-column
            label="企业编号"
            sortable
            prop="companyId"
            min-width="180"
          ></el-table-column>
          <el-table-column
            label="区域"
            prop="companyInTown"
            sortable
            width="160"
          ></el-table-column>
          <el-table-column
            label="行业"
            prop="companyType"
            sortable
            width="100"
          ></el-table-column>
          <el-table-column
            label="公司名称"
            prop="companyName"
            min-width="200"
          ></el-table-column>
          <el-table-column
            label="总自来水进水(吨)"
            prop="totalTapWater"
            width="170"
            :sortable="true"
            :sort-method="totalTapWaterSort"
            align="center"
          >
            <template slot-scope="scope">
              <div class="total-tap-water-col">
                {{ scope.row.totalTapWater }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="工业回用水(吨)"
            prop="totalIndustrialReuseWater"
            width="170"
            :sortable="true"
            :sort-method="totalIndustrialReuseWaterSort"
            align="center"
          >
            <template slot-scope="scope">
              <div class="total-industrial-reuse-water-col">
                {{ scope.row.totalIndustrialReuseWater }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="工业补水(吨)"
            prop="totalIndustrialAddWater"
            width="170"
            :sortable="true"
            :sort-method="totalIndustrialAddWaterSort"
            align="center"
          >
            <template slot-scope="scope">
              <div class="total-industrial-add-water-col">
                {{ scope.row.totalIndustrialAddWater }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="生活用水(吨)"
            prop="totalDomesticWater"
            width="170"
            :sortable="true"
            :sort-method="totalDomesticWaterSort"
            align="center"
          >
            <template slot-scope="scope">
              <div class="total-domestic-water-col">
                {{ scope.row.totalDomesticWater }}
              </div>
            </template>
          </el-table-column>

          <el-table-column label="详情查看" width="120">
            <template slot-scope="scope">
              <div class="button-col">
                <el-button
                  type="text"
                  @click="enterCompanyMonitor(scope.row.companyId)"
                >
                  进入查看
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>

        <!-- 分页组件 -->
        <!-- <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pagenum"
        :page-sizes="[5, 10, 20, 50]"
        :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination> -->
      </el-card>
      <!-- <div class="bottom-bg"></div> -->
    </div>
    <el-dialog
      title="提示"
      :visible.sync="tokenErrorDialogVisible"
      @close="tokenErrorDialogVisible = false"
      width="30%"
      :show-close="false"
    >
      <span>登录超时，请重新登录！</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="goLogin">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      hasErrorData: false,
      errorDataDrawer: false,
      processErrorTab: "toProcessError",
      toProcessArr: [],
      processingArr: [],
      moreThanTatolArr: [],
      currentErrorPage_1: 1, //未处理异常当前页面
      currentErrorPage_2: 1, //正在处理异常当前页面
      currentErrorPage_1_companyId: "",
      currentErrorPage_2_companyId: "",
      errorButtonText: "暂无异常",
      errorButtonType: "info",

      isInDeActived: false,
      tokenErrorDialogVisible: false,
      sType: "allCompanysInit",
      regionCode: "",
      companys: [],
      firstActived: true,
      area: "",
      areaCode: [],
      companyType: "",
      companyKeyword: "",

      tablePosition: 0,
      nowTime: "",
      // companyList: [
      //   {
      //     companyName: "惠州市达源五金制品有限公司", //公司名称
      //     companyId: "HC_XJK_0001", //公司编号
      //     companyInTown: "惠城_小金口",
      //     companyType: "五金",
      //     companyContact: "黄先生",
      //     totalTapWater: 20, //总自来水当日总计，单位KL
      //     totalIndustrialReuseWater: 10, //总工业回用水当日总计，单位KL
      //     totalIndustrialAddWater: 5, //总工业补水当日总计，单位KL
      //     totalDomesticWater: 15,
      //   },
      //   {
      //     companyName: "惠州荣大五金制品有限公司", //公司名称
      //     companyId: "HC_LL_0001", //公司编号
      //     companyInTown: "惠城_沥林",
      //     companyType: "五金",
      //     companyContact: "黄先生",
      //     totalTapWater: 30, //总自来水当日总计，单位KL
      //     totalIndustrialReuseWater: 5, //总工业回用水当日总计，单位KL
      //     totalIndustrialAddWater: 7, //总工业补水当日总计，单位KL
      //     totalDomesticWater: 23,
      //   },
      //   {
      //     companyName: "惠州恒益五金制品有限公司", //公司名称
      //     companyId: "HC_LL_0002", //公司编号
      //     companyInTown: "惠城_沥林",
      //     companyType: "五金",
      //     companyContact: "黄先生",
      //     totalTapWater: 10, //总自来水当日总计，单位KL
      //     totalIndustrialReuseWater: 3, //总工业回用水当日总计，单位KL
      //     totalIndustrialAddWater: 3, //总工业补水当日总计，单位KL
      //     totalDomesticWater: 7,
      //   },
      //   {
      //     companyName: "惠州市弘业五金制品有限公司", //公司名称
      //     companyId: "HC_XJK_0002", //公司编号
      //     companyInTown: "惠城_小金口",
      //     companyType: "五金",
      //     companyContact: "黄先生",
      //     totalTapWater: 60, //总自来水当日总计，单位KL
      //     totalIndustrialReuseWater: 20, //总工业回用水当日总计，单位KL
      //     totalIndustrialAddWater: 15, //总工业补水当日总计，单位KL
      //     totalDomesticWater: 45,
      //   },
      //   {
      //     companyName: "惠州大铎五金制品有限公司", //公司名称
      //     companyId: "HC_LL_0003", //公司编号
      //     companyInTown: "惠城_沥林",
      //     companyType: "五金",
      //     companyContact: "黄先生",
      //     totalTapWater: 35, //总自来水当日总计，单位KL
      //     totalIndustrialReuseWater: 15, //总工业回用水当日总计，单位KL
      //     totalIndustrialAddWater: 2, //总工业补水当日总计，单位KL
      //     totalDomesticWater: 33,
      //   },
      //   {
      //     companyName: "惠州市福利五金厂", //公司名称
      //     companyId: "HC_XJK_0003", //公司编号
      //     companyInTown: "惠城_小金口",
      //     companyType: "五金",
      //     companyContact: "黄先生",
      //     totalTapWater: 7, //总自来水当日总计，单位KL
      //     totalIndustrialReuseWater: 2.5, //总工业回用水当日总计，单位KL
      //     totalIndustrialAddWater: 1.25, //总工业补水当日总计，单位KL
      //     totalDomesticWater: 5.75,
      //   },
      //   {
      //     companyName: "汉宝（惠州）塑胶五金制品有限公司", //公司名称
      //     companyId: "HC_LL_0004", //公司编号
      //     companyInTown: "惠城_沥林",
      //     companyType: "五金",
      //     companyContact: "黄先生",
      //     totalTapWater: 20, //总自来水当日总计，单位KL
      //     totalIndustrialReuseWater: 10, //总工业回用水当日总计，单位KL
      //     totalIndustrialAddWater: 5, //总工业补水当日总计，单位KL
      //     totalDomesticWater: 15,
      //   },
      //   {
      //     companyName: "惠州市泽沣五金有限公司", //公司名称
      //     companyId: "HC_XJK_0004", //公司编号
      //     companyInTown: "惠城_小金口",
      //     companyType: "五金",
      //     companyContact: "黄先生",
      //     totalTapWater: 30, //总自来水当日总计，单位KL
      //     totalIndustrialReuseWater: 5, //总工业回用水当日总计，单位KL
      //     totalIndustrialAddWater: 7, //总工业补水当日总计，单位KL
      //     totalDomesticWater: 23,
      //   },
      //   {
      //     companyName: "骏宝利铭板五金（惠州）有限公司", //公司名称
      //     companyId: "HC_XJK_0005", //公司编号
      //     companyInTown: "惠城_小金口",
      //     companyType: "五金",
      //     companyContact: "黄先生",
      //     totalTapWater: 10, //总自来水当日总计，单位KL
      //     totalIndustrialReuseWater: 3, //总工业回用水当日总计，单位KL
      //     totalIndustrialAddWater: 3, //总工业补水当日总计，单位KL
      //     totalDomesticWater: 7,
      //   },
      //   {
      //     companyName: "惠州市宏富精密五金部件有限公司", //公司名称
      //     companyId: "HC_LL_0005", //公司编号
      //     companyInTown: "惠城_沥林",
      //     companyType: "五金",
      //     companyContact: "黄先生",
      //     totalTapWater: 60, //总自来水当日总计，单位KL
      //     totalIndustrialReuseWater: 20, //总工业回用水当日总计，单位KL
      //     totalIndustrialAddWater: 15, //总工业补水当日总计，单位KL
      //     totalDomesticWater: 45,
      //   }
      // ],
      companyList: [],
      // area: "",
      selectableArea: {
        RC: [
          // "沥林",
          // "小金口","汝湖",
          // "三栋","马安",
          // "潼湖","横沥","芦洲",
          "梅云",
          "仙桥",
          "渔湖",
          "溪南",
          "凤美",
          "京冈",
          "炮台",
          "地都",
          "登岗",
          "中山",
          "新兴",
          "榕华",
          "西马",
          "榕东",
          "东升",
          "东兴",
          "东阳",
        ],
        JD: [
          "曲溪",
          "磐东",
          "云路",
          "玉窖",
          "锡场",
          "新亨",
          "玉湖",
          "埔田",
          "霖磐",
          "月城",
          "白塔",
          "龙尾",
          "桂岭",
        ],
        PN: [
          "流沙东",
          "流沙西",
          "流沙南",
          "流沙北",
          "池尾",
          "燎原",
          "大南山",
          "占陇",
          "里湖",
          "洪阳",
          "军埠",
          "梅塘",
          "大坝",
          "南径",
          "麒麟",
          "南溪",
          "广太",
          "赤岗",
          "梅林",
          "云落",
          "高埔",
          "船埔",
          "大坪",
          "下架山",
          "后溪",
          "普侨",
        ],
        JX: [
          "河婆",
          "棉湖",
          "五经富",
          "龙潭",
          "京溪园",
          "五云",
          "坪上",
          "南山",
          "东园",
          "凤江",
          "灰寨",
          "上砂",
          "钱坑",
          "金和",
          "大溪",
          "塔头",
          "良田",
        ],
        HL: [
          "惠城",
          "华湖",
          "东陇",
          "神泉",
          "隆江",
          "葵潭",
          "靖海",
          "前詹",
          "岐石",
          "溪西",
          "鳌江",
          "东港",
          "仙庵",
          "周田",
          "侨园",
        ],
        JY: [
          {
            label: "榕城",
            value: "RC",
            children: [
              { label: "梅云", value: "MY" },
              { label: "仙桥", value: "XQ" },
              { label: "渔湖", value: "YH" },
              { label: "溪南", value: "XN" },
              { label: "凤美", value: "FM" },
              { label: "京冈", value: "JG" },
              { label: "炮台", value: "PT" },
              { label: "地都", value: "DD" },
              { label: "登岗", value: "DG" },
              { label: "中山", value: "ZS" },
              { label: "新兴", value: "XX" },
              { label: "榕华", value: "RH" },
              { label: "西马", value: "XM" },
              { label: "榕东", value: "RD" },
              { label: "东升", value: "DS" },
              { label: "东兴", value: "DX" },
              { label: "东阳", value: "DY" },
            ],
          },
          {
            label: "揭东",
            value: "JD",
            children: [
              { label: "曲溪", value: "QX" },
              { label: "磐东", value: "PD" },
              { label: "云路", value: "YL" },
              { label: "玉窖", value: "YJ" },
              { label: "锡场", value: "XC" },
              { label: "新亨", value: "XH" },
              { label: "玉湖", value: "YH" },
              { label: "埔田", value: "PT" },
              { label: "霖磐", value: "LP" },
              { label: "月城", value: "YC" },
              { label: "白塔", value: "BT" },
              { label: "龙尾", value: "LW" },
              { label: "桂岭", value: "GL" },
            ],
          },
          {
            label: "普宁",
            value: "PN",
            children: [
              { label: "流沙东", value: "LSD" },
              { label: "流沙西", value: "LSX" },
              { label: "流沙南", value: "LSN" },
              { label: "流沙北", value: "LSB" },
              { label: "池尾", value: "CW" },
              { label: "燎原", value: "LY" },
              { label: "大南山", value: "DNS" },
              { label: "占陇", value: "ZL" },
              { label: "里湖", value: "LH" },
              { label: "洪阳", value: "HY" },
              { label: "军埠", value: "JB" },
              { label: "梅塘", value: "MT" },
              { label: "大坝", value: "DB" },
              { label: "南径", value: "NJ" },
              { label: "麒麟", value: "QL" },
              { label: "南溪", value: "NX" },
              { label: "广太", value: "GT" },
              { label: "赤岗", value: "CG" },
              { label: "梅林", value: "ML" },
              { label: "云落", value: "YL" },
              { label: "高埔", value: "GP" },
              { label: "船埔", value: "CP" },
              { label: "大坪", value: "DP" },
              { label: "下架山", value: "XJS" },
              { label: "后溪", value: "HX" },
              { label: "普侨", value: "PQ" },
            ],
          },
          {
            label: "揭西",
            value: "JX",
            children: [
              { label: "河婆", value: "HP" },
              { label: "棉湖", value: "MH" },
              { label: "五经富", value: "WJF" },
              { label: "龙潭", value: "LT1" },
              { label: "京溪园", value: "JXY" },
              { label: "五云", value: "WY" },
              { label: "坪上", value: "PS" },
              { label: "南山", value: "NS" },
              { label: "东园", value: "DY" },
              { label: "凤江", value: "FJ" },
              { label: "灰寨", value: "HZ" },
              { label: "上砂", value: "SS" },
              { label: "钱坑", value: "QK" },
              { label: "金和", value: "JH" },
              { label: "大溪", value: "DX" },
              { label: "塔头", value: "TT" },
              { label: "良田", value: "LT2" },
            ],
          },
          {
            label: "惠来",
            value: "HL",
            children: [
              { label: "惠城", value: "HC" },
              { label: "华湖", value: "HH" },
              { label: "东陇", value: "DL" },
              { label: "神泉", value: "SQ" },
              { label: "隆江", value: "LJ" },
              { label: "葵潭", value: "KT" },
              { label: "靖海", value: "JH" },
              { label: "前詹", value: "QZ" },
              { label: "岐石", value: "QS" },
              { label: "溪西", value: "XX" },
              { label: "鳌江", value: "AJ" },
              { label: "东港", value: "DG" },
              { label: "仙庵", value: "XA" },
              { label: "周田", value: "ZT" },
              { label: "侨园", value: "QY" },
            ],
          },
        ],
      },

      // companyType: "",
      selectableCompanyType: [
        "五金",
        "钢铁",
        "印染",
        "印花",
        "化工",
        "制药",
        "肉制品",
        "食品",
        "腌制",
        "屠宰",
      ],
    };
  },
  methods: {
    openErrorDataDrawer() {
      this.errorDataDrawer = true;
      // this.currentErrorPage_1 = 1;
      // this.currentErrorPage_2 = 1;
    },
    changeProcessErrorTab(e) {
      // console.log(e);
    },
    currentErrorCompanyChange_1(i) {
      // console.log(i);
      this.currentErrorPage_1 = i;
      this.currentErrorPage_1_companyId =
        this.toProcessArr[this.currentErrorPage_1 - 1].companyId;
      // console.log(this.currentErrorPage_1_companyId);
    },
    currentErrorCompanyChange_2(i) {
      // console.log(i);
      this.currentErrorPage_2 = i;
      this.currentErrorPage_2_companyId =
        this.processingArr[this.currentErrorPage_2 - 1].companyId;
      // console.log(this.currentErrorPage_2_companyId);
    },
    goLogin() {
      this.tokenErrorDialogVisible = false;
      this.errorDataDrawer = false;
      this.$router.push("/login");
    },
    tableRowClassName({ rowIndex }) {
      if (rowIndex % 2 == 0) {
        return "dark-row-2";
      } else {
        return "dark-row-1";
      }
    },
    tableHeaderClassName() {
      return "table-header";
    },
    onSelectAreaBlur(e) {
      if (e.target.value) {
        this.area = e.target.value;
      }
    },
    enterCompanyMonitor(companyId) {
      this.$router.push({
        path: "/companyhome",
        query: { companyId: companyId },
      });
    },
    selecteArea() {
      let checkedNode = this.$refs.selecteAreaLabelRef.getCheckedNodes();
      if (checkedNode.length < 1) {
        this.area = "";
      } else {
        this.area = checkedNode[0].pathLabels.join("_");
      }
    },
    clearAreaAndType() {
      this.area = "";
      if (this.regionCode == "JY") {
        let checkedNode = this.$refs.selecteAreaLabelRef.getCheckedNodes();
        checkedNode = [];
        this.areaCode = [];
      }
      this.companyType = "";
    },
    clearKeyword() {
      this.companyKeyword = "";
    },
    setCompanysDataInit(data) {
      // console.log(data);
      this.hasErrorData = false;
      this.toProcessArr = [];
      this.processingArr = [];
      this.moreThanTatolArr = [];
      this.currentErrorPage_1 = 1; //未处理异常当前页面
      this.currentErrorPage_2 = 1; //正在处理异常当前页面

      data.forEach((elem, index) => {
        // console.log(elem._7day_data,elem.currentRecord);
        elem._7day_data = elem._7day_data.split("/");
        elem.currentRecord = elem.currentRecord.split("/");
        elem.totalTapWater = (
          elem.currentRecord[0] - elem._7day_data[0]
        ).toFixed(2);
        elem.totalIndustrialReuseWater = (
          elem.currentRecord[1] - elem._7day_data[1]
        ).toFixed(2);
        elem.totalIndustrialAddWater = (
          elem.currentRecord[2] - elem._7day_data[2]
        ).toFixed(2);
        elem.totalDomesticWater = (
          elem.totalTapWater - elem.totalIndustrialAddWater
        ).toFixed(2);
        // console.log(elem);
        if (
          elem.currentStateArr &&
          elem.currentStateArr.indexOf("error") >= 0
        ) {
          // console.log(elem);
          let normalArr = elem.nearlyNormalRecord.split("#");
          let normalTimeStamp = parseInt(normalArr[0]);
          let normalTime = this.dayjs(new Date(parseInt(normalArr[0]))).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          let normalRecordArr = normalArr[1].split("/").map((e) => {
            return e - 0;
          });
          // console.log(normalTime);
          // console.log(normalRecord);
          let errorTimeStamps = [];
          let errorTimes = elem.errorDataArr.map((e) => {
            // console.log(e);
            let time = e.split("#")[0];
            errorTimeStamps.push(parseInt(time));
            // console.log(new Date(parseInt(time)));
            return this.dayjs(new Date(parseInt(time))).format(
              "YYYY-MM-DD HH:mm:ss"
            );
          });
          // console.log(errorTimeStamps);
          // console.log(errorTimes);
          let errorRecordArrs = elem.errorDataArr.map((e) => {
            let records = e.split("#")[1];
            return records.split("/");
          });
          // console.log(errorTimes);
          // console.log(errorRecordArrs);
          let obj_1 = {
            //存放该公司未处理异常流量计
            companyId: elem.companyId,
            companyName: elem.companyName,
            companyPhone: elem.companyPhone,
            companyContact: elem.companyContact,
            flowMonitors: [],
          };
          let obj_2 = {
            //存放该公司正在处理异常流量计
            companyId: elem.companyId,
            companyName: elem.companyName,
            companyPhone: elem.companyPhone,
            companyContact: elem.companyContact,
            flowMonitors: [],
          };
          elem.currentStateArr.forEach((e, i) => {
            // console.log(i);
            if (e == "error") {
              let str = `${elem.companyId}的${elem.companyId}_`;
              elem.errorMsgArr[i] = elem.errorMsgArr[i].replace(str, "");
              if (i == 0) {
                elem.errorMsgArr[i] = elem.errorMsgArr[i].replace(
                  "01",
                  "总自来水进水"
                );
              } else if (i == 1) {
                elem.errorMsgArr[i] = elem.errorMsgArr[i].replace(
                  "02",
                  "工业回用水"
                );
              } else {
                elem.errorMsgArr[i] = elem.errorMsgArr[i].replace(
                  "03",
                  "工业补水"
                );
              }
              let obj = {
                errorMsg: elem.errorMsgArr[i],
                maxFlowPerMin: elem.maxFlowPerMinArr[i],
                normalTimeStamp: normalTimeStamp,
                normalTime: normalTime,
                normalRecord: normalRecordArr[i],
                errorTimeStamps: errorTimeStamps,
                errorTimes: errorTimes,
              };

              if (elem.errorMsgArr[i].indexOf("网络异常") < 0) {
                obj.errorRecordArr = errorRecordArrs.map((e) => {
                  return e[i] - 0;
                });
                let isNormalArr = [];
                obj.errorRecordArr.forEach((e, i) => {
                  let preTime;
                  let preRecord;
                  let time = obj.errorTimeStamps[i];
                  let record = obj.errorRecordArr[i];
                  if (i == 1 || i == 0) {
                    preTime = obj.normalTimeStamp;
                    preRecord = obj.normalRecord;
                  } else {
                    preTime = obj.errorTimeStamps[i - 1];
                    preRecord = obj.errorRecordArr[i - 1];
                  }
                  let a = preTime;
                  let b = time;
                  let mins = (b - a) / 60000;
                  if (0 < mins && mins < 1) mins = 1;
                  let duringMins = (b - obj.normalTimeStamp) / 60000;
                  if (0 < duringMins && duringMins < 1) duringMins = 1;

                  // if(i==0){
                  //   console.log(preRecord,record,mins,preTime,time);
                  //   console.log(record - preRecord > mins * obj.maxFlowPerMin ||
                  //     preRecord - record > 0.1
                  //     ? "异常"
                  //     : "正常");
                  // }
                  isNormalArr.push(
                    record - preRecord > mins * obj.maxFlowPerMin ||
                      record - obj.normalRecord >
                        duringMins * obj.maxFlowPerMin ||
                      preRecord - record > 0.1
                      ? "异常"
                      : "正常"
                  );
                  // console.log(preTime,preRecord,time,record,min);
                });
                // console.log(isNormalArr);
                obj.isNormalArr = isNormalArr;
                let a = obj.normalTimeStamp;
                let b = obj.errorTimeStamps[obj.errorTimeStamps.length - 1];
                let mins = (b - a) / 60000;
                if (0 < mins && mins < 1) mins = 1;
                // console.log(a);
                // console.log(b);
                // console.log(b - a);
                // console.log(mins);
                obj.flowDuringError =
                  obj.errorRecordArr[obj.errorTimeStamps.length - 1] -
                  obj.normalRecord;
                // console.log(obj.errorTimeStamps);
                obj.isNormal =
                  obj.flowDuringError > mins * obj.maxFlowPerMin ||
                  -1 * obj.flowDuringError > 0.1
                    ? "异常"
                    : "正常";
              } else {
                obj.errorTimeStamps = [Math.random()];
                obj.errorTimes = ["未知时间"];
                obj.errorRecordArr = ["未知"];
                obj.isNormalArr = ["异常"];
                obj.isNormal = "异常";
                obj.flowDuringError = "未知";
              }

              // console.log(obj);

              if (!elem.errorIsProcessingArr) {
                obj_1.flowMonitors.push(obj);
                if (!this.hasErrorData) {
                  this.hasErrorData = true;
                  this.processErrorTab = "toProcessError";
                }
              } else {
                obj_2.flowMonitors.push(obj);
              }
            }
          });
          if (obj_1.flowMonitors.length > 0) {
            this.toProcessArr.push(obj_1);
          }
          if (obj_2.flowMonitors.length > 0) {
            obj_2.isNormal = "正常";
            obj_2.flowMonitors.forEach((monitor) => {
              if (monitor.isNormal == "异常") obj_2.isNormal = "异常";
            });
            this.processingArr.push(obj_2);
          }
        } 
        // else {
        //   if (
        //     elem.totalIndustrialAddWater - elem.totalTapWater >
        //     (elem.totalTapWater - 0) * 0.05
        //   ) {
        //     // console.log('aaaa');
        //     let obj_3 = {
        //       companyId: elem.companyId,
        //       companyName: elem.companyName,
        //       companyPhone: elem.companyPhone,
        //       companyContact: elem.companyContact,
        //       totalTapWater: elem.totalTapWater,
        //       totalIndustrialAddWater: elem.totalIndustrialAddWater,
        //     };
        //     this.moreThanTatolArr.push(obj_3);
        //   }
        // }


        //判断工业用水是否大于总自来水进水
        if (
            elem.totalIndustrialAddWater - elem.totalTapWater >
            (elem.totalTapWater - 0) * 0.05
          ) {
            // console.log('aaaa');
            let obj_3 = {
              companyId: elem.companyId,
              companyName: elem.companyName,
              companyPhone: elem.companyPhone,
              companyContact: elem.companyContact,
              totalTapWater: elem.totalTapWater,
              totalIndustrialAddWater: elem.totalIndustrialAddWater,
            };
            this.moreThanTatolArr.push(obj_3);
          }


      });
      // console.log(this.moreThanTatolArr);
      //控制未处理异常和正在处理异常面板中显示哪一页
      if (this.toProcessArr.length > 0) {
        let flag = true;
        for (let i = 0; i < this.toProcessArr.length; i++) {
          if (
            this.toProcessArr[i].companyId == this.currentErrorPage_1_companyId
          ) {
            this.currentErrorPage_1 = i + 1;
            flag = false;
            break;
          }
        }
        if (flag) {
          this.currentErrorPage_1_companyId = this.toProcessArr[0].companyId;
        }
      }
      if (this.processingArr.length > 0) {
        // console.log('object');
        let flag = true;
        for (let i = 0; i < this.processingArr.length; i++) {
          if (
            this.processingArr[i].companyId == this.currentErrorPage_2_companyId
          ) {
            this.currentErrorPage_2 = i + 1;
            flag = false;
            break;
          }
        }
        if (flag) {
          this.currentErrorPage_2_companyId = this.processingArr[0].companyId;
        }
      }
      //控制异常面板激活哪一个标签
      if (this.toProcessArr.length > 0) {
        // console.log('a');
        this.processErrorTab = "toProcessError";
      } else if (
        this.processingArr.length > 0 &&
        this.moreThanTatolArr.length < 1
      ) {
        // console.log('b');
        this.processErrorTab = "processingError";
      } else if (
        (this.processingArr.length < 1 && this.moreThanTatolArr.length > 0) ||
        (this.toProcessArr.length < 1 &&
          this.moreThanTatolArr.length > 0 &&
          this.processErrorTab == "moreThanError")
      ) {
        // console.log('c');
        this.processErrorTab = "moreThanError";
      } else {
        this.processErrorTab = "processingError";
      }
      if (this.toProcessArr.length > 0) {
        this.errorButtonText = "发生异常";
        this.errorButtonType = "danger";
      } else if (
        this.processingArr.length > 0 ||
        this.moreThanTatolArr.length > 0
      ) {
        this.errorButtonText = "查看异常";
        this.errorButtonType = "warning";
      } else {
        this.errorButtonText = "暂无异常";
        this.errorButtonType = "info";
      }

      // console.log(this.toProcessArr);
      // console.log(this.processingArr);
      this.companyList = data;
      this.loading = false;
    },
    async GetAllCompanysInfo() {
      const res = await this.$http.post(
        "api/Cobweb.MES.IMESAPI/ApiService/GetAllCompanysInfo",
        {
          sType: this.sType,
        }
      );
      if (res.data.Status != 200) {
        if (this.isInDeActived) return;
        if (res.data.Status == 400) {
          if (res.data.Data.tokenIsWrong == true) {
            this.tokenErrorDialogVisible = true;
            return;
          }
        }
        return this.$message.error(res.data.Message);
      }
      this.setCompanysDataInit(res.data.Data);
    },
    async startProcess(companyId) {
      // console.log(companyId);
      this.loading = true;
      this.currentErrorPage_2_companyId = companyId;
      const res = await this.$http.post(
        "/api/Cobweb.MES.IMESAPI/ApiService/ManualHanding",
        {
          companyId: companyId,
          sType: 1,
        }
      );
      // console.log(res);
      if (res.data.Status != 200) {
        return this.$message.error(res.data.Message);
      }
      this.GetAllCompanysInfo();
    },
    async cofirmNormal(companyId) {
      this.loading = true;
      const res = await this.$http.post(
        "/api/Cobweb.MES.IMESAPI/ApiService/ManualHanding",
        {
          companyId: companyId,
          sType: 2,
        }
      );
      // console.log(res);
      if (res.data.Status != 200) {
        return this.$message.error(res.data.Message);
      }
      this.GetAllCompanysInfo();
    },
    totalTapWaterSort(a, b) {
      if (a.totalTapWater - b.totalTapWater >= 0) {
        return 1;
      } else {
        return -1;
      }
    },
    totalIndustrialReuseWaterSort(a, b) {
      if (a.totalIndustrialReuseWater - b.totalIndustrialReuseWater >= 0) {
        return 1;
      } else {
        return -1;
      }
    },
    totalIndustrialAddWaterSort(a, b) {
      if (a.totalIndustrialAddWater - b.totalIndustrialAddWater >= 0) {
        return 1;
      } else {
        return -1;
      }
    },
    totalDomesticWaterSort(a, b) {
      if (a.totalDomesticWater - b.totalDomesticWater >= 0) {
        return 1;
      } else {
        return -1;
      }
    },
  },
  created() {
    // console.log('created');
    let qp = this.$route.query;
    if (this.$route.query.type == "region") {
      this.regionCode = qp.regionCode;
      // console.log(this.regionCode);
    } else if (this.$route.query.type == "company") {
      this.companys = JSON.parse(qp.companys);
      // console.log(this.companys);
    }
  },
  async mounted() {
    // console.log('mounted');
    /* 每秒刷新 */
    // console.log(1111);
    this.nowTime = this.dayjs().format("YYYY-MM-DD HH:mm:ss");
    this.time = setInterval(() => {
      this.nowTime = this.dayjs().format("YYYY-MM-DD HH:mm:ss");
    }, 1000);

    this.$refs.monitorTable.bodyWrapper.addEventListener(
      "scroll",
      (res) => {
        let height = res.target;
        this.tablePosition = height.scrollTop;
      },
      false
    );
    // return
    if (this.regionCode != "") {
      if (this.regionCode != "JY") this.sType = this.regionCode;
    } else {
      this.regionCode = "JY";
      this.sType = this.companys;
    }
    // console.log(this.sType);
    const res = await this.$http.post(
      "api/Cobweb.MES.IMESAPI/ApiService/GetAllCompanysInfo",
      {
        sType: this.sType,
      }
    );
    // console.log(res);
    if (res.data.Status != 200) {
      if (res.data.Status == 400) {
        if (res.data.Data.tokenIsWrong == true) {
          this.tokenErrorDialogVisible = true;
          return;
        }
      }
      return this.$message.error(res.data.Message);
    }
    this.setCompanysDataInit(res.data.Data);
    this.getDataInter = setInterval(async () => {
      // console.log(this.sType);
      const res = await this.$http.post(
        "api/Cobweb.MES.IMESAPI/ApiService/GetAllCompanysInfo",
        {
          sType: this.sType,
        }
      );
      // console.log(res);
      if (res.data.Status != 200) {
        if (this.isInDeActived) return;
        if (res.data.Status == 400) {
          if (res.data.Data.tokenIsWrong == true) {
            this.tokenErrorDialogVisible = true;
            return;
          }
        }
        return this.$message.error(res.data.Message);
      }
      this.setCompanysDataInit(res.data.Data);
    }, 60000);
  },
  beforeDestroy() {
    this.$refs.monitorTable.removeEventListener(
      "scroll",
      (res) => {
        let height = res.target;
        this.tablePosition = height.scrollTop;
      },
      false
    );
    if (this.time) {
      clearInterval(this.time);
    }
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prePagePath = from.path;
    });
  },
  deactivated() {
    this.isInDeActived = true;
    // console.log(this.isInDeActived);
  },
  activated() {
    // console.log('actived');
    // let qp = this.$route.query;
    // if (this.$route.query.type == "region") {
    //   if (this.regionCode != qp.regionCode) this.$router.go(0);
    // }
    this.isInDeActived = false;
    // console.log(this.isInDeActived);
    if (this.firstActived) {
      this.firstActived = false;
      return;
    }
    // console.log(this.prePagePath);
    if (this.prePagePath == "/login") {
      this.$router.go(0);
    }

    let scrollTop = this.$el.querySelector(".el-table__body-wrapper");
    scrollTop.scrollTop = this.tablePosition;
  },
};
</script>

<style scoped>
.monitor-card,
.search-card {
  width: 100%;
  background-color: #100c2a;
  border: none;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5) !important;
  /* border: 1px solid rgba(255, 255, 255, 0.8); */
}
.search-card {
  margin-bottom: 15px;
}
.monitor-card {
  height: 790px;
  padding-top: 10px;
}
.search-label {
  color: white;
  /* font-size: 1.2em; */
  margin-right: 15px;
}
.search-btn,
.search-btn:focus {
  background: rgb(14, 3, 44);
}
.search-btn:hover {
  background: rgb(36, 17, 88);
}
@keyframes change-color {
  from {
    background-color: rgb(140, 3, 44);
  }
  50% {
    background-color: rgb(140, 3, 44);
  }
  51% {
    background-color: rgb(220, 3, 44);
  }
  to {
    background-color: rgb(220, 3, 44);
  }
}
.has-error-data-btn {
  background-color: rgb(140, 3, 44);
  border: none;
  animation: change-color 0.5s alternate infinite;
}

.company-sarech-area {
  display: flex;
  align-items: center;
  flex-basis: 35%;
}

.monitor-title {
  position: relative;
  border-bottom: rgba(128, 128, 128, 0.5) 1px solid;
  color: darkorange;
  font-size: 1.2em;
  text-align: center;
  padding-bottom: 5px;
  letter-spacing: 2px;
}
.date-time {
  position: absolute;
  top: 0;
  right: 0;
  /* vertical-align: middle; */
  margin-left: 15px;
  font-size: 0.8em;
  /* padding-bottom: 0.2em; */
  color: #ccc;
  letter-spacing: 0;
}
.total-tap-water-col {
  height: 40px;
  width: 100%;
  line-height: 40px;
  color: #409eff;
  /* font-weight: bold; */
  /* border: solid 1px #409EFF; */
  /* border-radius: 10px; */
}
.total-industrial-reuse-water-col {
  height: 40px;
  width: 100%;
  line-height: 40px;
  color: #67c23a;
  /* font-weight: bold; */
}
.total-industrial-add-water-col {
  height: 40px;
  width: 100%;
  line-height: 40px;
  color: #e6a23c;
  /* font-weight: bold; */
}
.total-domestic-water-col {
  height: 40px;
  width: 100%;
  line-height: 40px;
  color: #f56c6c;
  /* font-weight: bold; */
}
.button-col {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.error-panel-item {
  margin: 5px 15px;
}
.error-table-container {
  display: flex;
  /* justify-content: space-around; */
}
.error-table {
  width: 345px;
  padding: 5px 15px;
  margin: 0 0 15px 15px;
  border: solid 1px rgba(0, 0, 0, 0.2);
}
.record-item {
  margin: 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>