<template>
  <div class="page_container">
    <div class="home_container">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-card class="monitor-card simple_card">
            <div class="title_area" style="border: none; height: 40px">
              <div>
                <el-button
                  v-if="isSupuerUser"
                  type="text"
                  icon="el-icon-back"
                  @click="backToHome"
                  style="font-size: 0.95em; padding-right: 20px"
                  >返回主页</el-button
                >
                <el-button
                  type="text"
                  @click="bornReportForm"
                  style="font-size: 1em"
                  >生成报表<i class="el-icon-download el-icon--right"></i
                ></el-button>
              </div>
            </div>
            <div class="company-overview">
              <div class="company_info">
                <div class="company_info_item">
                  <div>
                    <i class="el-icon-house" style="color: #409eff"></i>
                    公司名称：
                  </div>
                  <div>{{ resData.company_info.companyName }}</div>
                </div>

                <div class="company_info_item">
                  <div>
                    <i class="el-icon-tickets" style="color: #67c23a"></i>
                    公司编号：
                  </div>
                  <div>{{ resData.company_info.companyId }}</div>
                </div>

                <div class="company_info_item">
                  <div>
                    <i
                      class="el-icon-location-outline"
                      style="color: #e6a23c"
                    ></i>
                    公司地址：
                  </div>
                  <div>{{ resData.company_info.companyAddress }}</div>
                </div>

                <div class="company_info_item">
                  <div>
                    <i class="el-icon-user" style="color: #f56c6c"></i> 联系人：
                  </div>
                  <div>{{ resData.company_info.companyContact }}</div>
                </div>

                <div class="company_info_item">
                  <div>
                    <i class="el-icon-phone-outline" style="color: #409eff"></i>
                    联系电话：
                  </div>
                  <div>{{ resData.company_info.companyPhone }}</div>
                </div>
              </div>
              <div class="today-total-data">
                <el-row>
                  <el-col :span="24">
                    <div>今日各项流量实时总计</div>
                    <div class="date-time">{{ nowTime }}</div>
                  </el-col>
                </el-row>
                <el-col :span="12">
                  <div class="total-item">
                    <div class="total-item-name">总自来水进水</div>
                    <div class="total-item-value">
                      {{ resData.todayTotalData.totalTapWater }}吨
                    </div>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="total-item">
                    <div class="total-item-name">工业回用水</div>
                    <div class="total-item-value">
                      {{ resData.todayTotalData.totalIndustrialReuseWater }}吨
                    </div>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="total-item">
                    <div class="total-item-name">工业补水</div>
                    <div class="total-item-value">
                      {{ resData.todayTotalData.totalIndustrialAddWater }}吨
                    </div>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="total-item">
                    <div class="total-item-name">生活用水</div>
                    <div class="total-item-value">
                      {{ totalDomesticWater }}吨
                    </div>
                  </div>
                </el-col>
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card class="monitor-card simple_card">
            <div class="title_area">
              <div>工业用水回用率 (单位: %)</div>
              <div class="explain">
                <span
                  >注：回用率=某时段工业回用水总量÷同时段工业补水与工业回用水总量之和</span
                >
                <br />
                <span>上方公式除数为0时，回用率默认为100%</span>
              </div>
            </div>
            <div class="chart_monitor">
              <div id="chart_5" class="echart_box reuse_chart"></div>
              <div class="btns_group btns_reuse">
                <el-radio-group
                  v-model="chart_5_radio"
                  @input="chart_5_radio_changed"
                  size="mini"
                >
                  <el-radio-button label="天" border></el-radio-button>
                  <el-radio-button label="三天" border></el-radio-button>
                  <el-radio-button label="周" border></el-radio-button>
                  <el-radio-button label="月" border></el-radio-button>
                  <el-radio-button label="季度" border></el-radio-button>
                  <el-radio-button label="年" border></el-radio-button>
                </el-radio-group>
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card class="monitor-card">
            <div class="title_area">
              <div>
                总自来水进水_流量监控
                <span>(单位: 吨)</span>
              </div>
              <div class="explain">当前流量计值：{{ resData.tapWater_monitor.currentValue }} 吨</div>
            </div>
            <div class="chart_area">
              <div class="other_monitor">
                <div>当前水温:</div>
                <div>{{ resData.tapWater_monitor.waterTemperature }}</div>
                <div>今日峰值:</div>
                <div>{{ resData.tapWater_monitor.todayMaxVlue }}</div>
                <div>当前流速:</div>
                <div>{{ resData.tapWater_monitor.currentSpeed }}</div>
              </div>
              <div class="chart_monitor">
                <div id="chart_1" class="echart_box"></div>
                <div class="btns_group">
                  <el-radio-group
                    v-model="chart_1_radio"
                    @input="chart_1_radio_changed"
                    size="mini"
                  >
                    <el-radio-button label="分" border></el-radio-button>
                    <el-radio-button label="小时" border></el-radio-button>
                    <el-radio-button label="天" border></el-radio-button>
                    <el-radio-button label="三天" border></el-radio-button>
                    <el-radio-button label="周" border></el-radio-button>
                    <el-radio-button label="月" border></el-radio-button>
                    <el-radio-button label="季度" border></el-radio-button>
                    <el-radio-button label="年" border></el-radio-button>
                  </el-radio-group>
                </div>
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card class="monitor-card">
            <div class="title_area">
              <div>工业回用水_流量监控 <span>(单位: 吨)</span></div>
              <div class="explain">当前流量计值：{{ resData.industrialReuseWater_monitor.currentValue }} 吨</div>
            </div>
            <div class="chart_area">
              <div class="other_monitor">
                <div>当前水温:</div>
                <div>
                  {{ resData.industrialReuseWater_monitor.waterTemperature }}
                </div>
                <div>今日峰值:</div>
                <div>
                  {{ resData.industrialReuseWater_monitor.todayMaxVlue }}
                </div>
                <div>当前流速:</div>
                <div>
                  {{ resData.industrialReuseWater_monitor.currentSpeed }}
                </div>
              </div>
              <div class="chart_monitor">
                <div id="chart_2" class="echart_box"></div>
                <div class="btns_group">
                  <el-radio-group
                    v-model="chart_2_radio"
                    size="mini"
                    @input="chart_2_radio_changed"
                  >
                    <el-radio-button label="分" border></el-radio-button>
                    <el-radio-button label="小时" border></el-radio-button>
                    <el-radio-button label="天" border></el-radio-button>
                    <el-radio-button label="三天" border></el-radio-button>
                    <el-radio-button label="周" border></el-radio-button>
                    <el-radio-button label="月" border></el-radio-button>
                    <el-radio-button label="季度" border></el-radio-button>
                    <el-radio-button label="年" border></el-radio-button>
                  </el-radio-group>
                </div>
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card class="monitor-card bottom-card">
            <div class="title_area">
              <div>工业补水_流量监控 <span>(单位: 吨)</span></div>
              <div class="explain">当前流量计值：{{ resData.industrialAddWater_monitor.currentValue }} 吨</div>
            </div>
            <div class="chart_area">
              <div class="other_monitor">
                <div>当前水温:</div>
                <div>
                  {{ resData.industrialAddWater_monitor.waterTemperature }}
                </div>
                <div>今日峰值:</div>
                <div>{{ resData.industrialAddWater_monitor.todayMaxVlue }}</div>
                <div>当前流速:</div>
                <div>{{ resData.industrialAddWater_monitor.currentSpeed }}</div>
              </div>
              <div class="chart_monitor">
                <div id="chart_3" class="echart_box"></div>
                <div class="btns_group">
                  <el-radio-group
                    v-model="chart_3_radio"
                    size="mini"
                    @input="chart_3_radio_changed"
                  >
                    <el-radio-button label="分" border></el-radio-button>
                    <el-radio-button label="小时" border></el-radio-button>
                    <el-radio-button label="天" border></el-radio-button>
                    <el-radio-button label="三天" border></el-radio-button>
                    <el-radio-button label="周" border></el-radio-button>
                    <el-radio-button label="月" border></el-radio-button>
                    <el-radio-button label="季度" border></el-radio-button>
                    <el-radio-button label="年" border></el-radio-button>
                  </el-radio-group>
                </div>
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card class="monitor-card bottom-card">
            <div class="title_area">
              <div>生活用水_流量监控 <span>(单位: 吨)</span></div>
            </div>
            <div class="chart_area">
              <div class="other_monitor">
                <div>当前水温:</div>
                <div>{{ resData.tapWater_monitor.waterTemperature }}</div>
                <div>今日峰值:</div>
                <div>{{ resData.domesticWater_monitor.todayMaxVlue }}</div>
                <div>当前流速:</div>
                <div>
                  {{
                    (
                      parseFloat(resData.tapWater_monitor.currentSpeed) -
                      parseFloat(
                        resData.industrialAddWater_monitor.currentSpeed
                      )
                    ).toFixed(2)
                  }}L/min
                </div>
              </div>
              <div class="chart_monitor">
                <div id="chart_4" class="echart_box"></div>
                <div class="btns_group">
                  <el-radio-group
                    v-model="chart_4_radio"
                    size="mini"
                    @input="chart_4_radio_changed"
                  >
                    <el-radio-button label="分" border></el-radio-button>
                    <el-radio-button label="小时" border></el-radio-button>
                    <el-radio-button label="天" border></el-radio-button>
                    <el-radio-button label="三天" border></el-radio-button>
                    <el-radio-button label="周" border></el-radio-button>
                    <el-radio-button label="月" border></el-radio-button>
                    <el-radio-button label="季度" border></el-radio-button>
                    <el-radio-button label="年" border></el-radio-button>
                  </el-radio-group>
                </div>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <!-- <div class="bottom-bg"></div> -->
    </div>

    <el-dialog
      title="月度报表生成与下载"
      :visible.sync="reportFormdialogVisible"
      width="600px"
      @close="reportFormDialogClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div style="margin-bottom: 5px">生成并下载某个月的报表:</div>
      <div class="date-picker-container">
        <el-date-picker
          v-model="singleMonth"
          type="month"
          placeholder="选择单个月份"
          :editable="false"
          @change="singleMonthChange"
          :picker-options="pickerOption"
          :clearable="false"
          style="width: 330px"
        >
        </el-date-picker>
        <el-button
          type="primary"
          v-if="!singleBorned"
          :loading="singleBornning"
          style="margin-left: 12px; width: 200px"
          @click="singleMonthBorn"
          >{{ singleBornning ? "正在生成报表" : "生成报表" }}</el-button
        >
        <el-button
          type="danger"
          v-else
          style="margin-left: 12px; width: 200px"
          @click="downloadRportForm('s')"
          >下载报表</el-button
        >
      </div>
      <el-divider></el-divider>
      <div style="margin-bottom: 5px">生成并下载连续多个月的报表:</div>

      <div class="date-picker-container">
        <el-date-picker
          v-model="multiMonth"
          type="monthrange"
          :editable="false"
          range-separator="至"
          start-placeholder="开始月份"
          end-placeholder="结束月份"
          @change="multiMonthChange"
          :picker-options="pickerOption"
          :clearable="false"
          style="width: 330px"
        >
        </el-date-picker>
        <el-button
          type="primary"
          v-if="!multiBorned"
          :loading="multiBornning"
          style="margin-left: 12px; width: 200px"
          @click="multiMonthBorn"
          >{{ multiBornning ? "正在生成报表" : "生成报表" }}</el-button
        >
        <el-button
          type="danger"
          v-else
          style="margin-left: 12px; width: 200px"
          @click="downloadRportForm('m')"
          >下载报表</el-button
        >
      </div>

      <span slot="footer" class="dialog-footer"> </span>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="tokenErrorDialogVisible"
      @close="tokenErrorDialogVisible=false"
      width="30%"
      :show-close="false"
    >
      <span>登录超时，请重新登录！</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="goLogin">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as echarts from "echarts";
// import { Decimal } from "decimal.js";
export default {
  data() {
    return {
      tokenErrorDialogVisible: false,
      companyId: "",
      resData: {
        company_info: {
          companyName: "",
          companyId: "",
          companyAddress: "",
          companyContact: "",
          companyPhone: "",
        },
        todayTotalData: {
          totalTapWater: 0,
          totalIndustrialReuseWater: 0,
          totalIndustrialAddWater: 0,
        },
        tapWater_monitor: {
          currentValue:'',
          waterTemperature: "",
          todayMaxVlue: "",
          currentSpeed: "",
          min: {
            times: [],
            flowQty: [],
          },
          hour: {
            times: [],
            flowQty: [],
          },
          day: {
            times: [],
            flowQty: [],
          },
          threeDay: {
            times: [],
            flowQty: [],
          },
          week: {
            times: [],
            flowQty: [],
          },
          month: {
            times: [],
            flowQty: [],
          },
          season: {
            times: [],
            flowQty: [],
          },
          year: {
            times: [],
            flowQty: [],
          },
        },
        industrialReuseWater_monitor: {
          currentValue:'',
          waterTemperature: "",
          todayMaxVlue: "",
          currentSpeed: "",
          min: {
            times: [],
            flowQty: [],
          },
          hour: {
            times: [],
            flowQty: [],
          },
          day: {
            times: [],
            flowQty: [],
          },
          threeDay: {
            times: [],
            flowQty: [],
          },
          week: {
            times: [],
            flowQty: [],
          },
          month: {
            times: [],
            flowQty: [],
          },
          season: {
            times: [],
            flowQty: [],
          },
          year: {
            times: [],
            flowQty: [],
          },
        },
        industrialAddWater_monitor: {
          currentValue:'',
          waterTemperature: "",
          todayMaxVlue: "",
          currentSpeed: "",
          min: {
            times: [],
            flowQty: [],
          },
          hour: {
            times: [],
            flowQty: [],
          },
          day: {
            times: [],
            flowQty: [],
          },
          threeDay: {
            times: [],
            flowQty: [],
          },
          week: {
            times: [],
            flowQty: [],
          },
          month: {
            times: [],
            flowQty: [],
          },
          season: {
            times: [],
            flowQty: [],
          },
          year: {
            times: [],
            flowQty: [],
          },
        },
        domesticWater_monitor: {
          todayMaxVlue: "",
        },
        industriaWaterReuseRate: {
          day: {
            times: [],
            flowQty: [],
          },
        },
      },

      // resData: {
      //   company_info: {
      //     companyName: "惠州市达源五金制品有限公司",
      //     companyId: "HC_XJK_0001",
      //     companyAddress: "惠州市小金口街道办事处惠州大道(汤泉段)17号",
      //     companyContact: "甘先生",
      //     companyPhone: "13902657776",
      //   },
      //   todayTotalData: {
      //     totalTapWater: 20.0,
      //     totalIndustrialReuseWater: 10.0,
      //     totalIndustrialAddWater: 5.0,
      //   },
      //   tapWater_monitor: {
      //     waterTemperature: "23℃",
      //     todayMaxVlue: "10L/min",
      //     currentSpeed: "1.3L/min",
      //     min: {
      //       times: [
      //         "18:04",
      //         "18:05",
      //         "18:06",
      //         "18:07",
      //         "18:08",
      //         "18:09",
      //         "18:10",
      //       ],
      //       flowQty: [0.002, 0.004, 0.001, 0.003, 0.006, 0, 0.0025],
      //     },
      //     hour: {
      //       times: ["4:00", "5:00", "6:00", "7:00", "8:00", "9:00", "10:00"],
      //       flowQty: [0.1, 0.6, 0.5, 0.7, 0.4, 0.3, 1],
      //     },
      //     day: {
      //       times: ["2/6", "2/7", "2/8", "2/9", "2/10", "2/11", "2/12"],
      //       flowQty: [2.5, 10, 7, 10, 12, 7, 3.8],
      //     },
      //     threeDay: {
      //       times: [
      //         "12/5\n12/27",
      //         "12/28\n12/30",
      //         "12/31\n1/2",
      //         "1/3\n1/5",
      //         "1/6\n1/8",
      //         "1/9\n1/11",
      //         "1/12\n1/14",
      //       ],
      //       flowQty: [15, 7, 18, 30, 20, 27, 22.8],
      //     },
      //     week: {
      //       times: [
      //         "11/28\n12/3",
      //         "12/4\n12/10",
      //         "12/11\n12/17",
      //         "12/18\n12/24",
      //         "12/25\n12/31",
      //         "1/1\n1/7",
      //         "1/8\n1/14",
      //       ],
      //       flowQty: [51, 47, 60.6, 33.2, 40, 56, 60],
      //     },
      //     month: {
      //       times: ["6月", "7月", "8月", "9月", "10月", "11月", "12月"],
      //       flowQty: [110.6, 189, 201, 256, 222, 210, 108],
      //     },
      //     season: {
      //       times: [
      //         "4-6月",
      //         "7-9月",
      //         "10-12月",
      //         "1-3月",
      //         "4-6月",
      //         "7-9月",
      //         "10-12月",
      //       ],
      //       flowQty: [478, 501, 432.6, 508, 450, 646, 530],
      //     },
      //     year: {
      //       times: ["2016", "2017", "2018", "2019", "2020", "2021", "2022"],
      //       flowQty: [1408, 1670, 1509, 1726, 1802, 1919.2, 2207],
      //     },
      //   },
      //   industrialReuseWater_monitor: {
      //     waterTemperature: "23℃",
      //     todayMaxVlue: "8L/min",
      //     currentSpeed: "1.1L/min",
      //     min: {
      //       times: [
      //         "18:04",
      //         "18:05",
      //         "18:06",
      //         "18:07",
      //         "18:08",
      //         "18:09",
      //         "18:10",
      //       ],
      //       flowQty: [0.001, 0.002, 0.0015, 0.002, 0.005, 0, 0.002],
      //     },
      //     hour: {
      //       times: ["4:00", "5:00", "6:00", "7:00", "8:00", "9:00", "10:00"],
      //       flowQty: [0.1, 0.6, 0.5, 0.7, 0.4, 0.3, 1],
      //     },
      //     day: {
      //       times: ["1/8", "1/9", "1/10", "1/11", "1/12", "1/13", "1/14"],
      //       flowQty: [2, 7, 6, 5, 6, 4, 1.8],
      //     },
      //     threeDay: {
      //       times: [
      //         "12/5\n12/27",
      //         "12/28\n12/30",
      //         "12/31\n1/2",
      //         "1/3\n1/5",
      //         "1/6\n1/8",
      //         "1/9\n1/11",
      //         "1/12\n1/14",
      //       ],
      //       flowQty: [12, 6.6, 14, 15, 11.5, 22, 12.8],
      //     },
      //     week: {
      //       times: [
      //         "11/28\n12/3",
      //         "12/4\n12/10",
      //         "12/11\n12/17",
      //         "12/18\n12/24",
      //         "12/25\n12/31",
      //         "1/1\n1/7",
      //         "1/8\n1/14",
      //       ],
      //       flowQty: [37, 27, 38, 23.2, 25.5, 36, 40],
      //     },
      //     month: {
      //       times: ["6月", "7月", "8月", "9月", "10月", "11月", "12月"],
      //       flowQty: [60.6, 123, 151, 196, 143, 167, 88],
      //     },
      //     season: {
      //       times: [
      //         "4-6月",
      //         "7-9月",
      //         "10-12月",
      //         "1-3月",
      //         "4-6月",
      //         "7-9月",
      //         "10-12月",
      //       ],
      //       flowQty: [356, 401, 332.6, 388, 290, 446, 410],
      //     },
      //     year: {
      //       times: ["2016", "2017", "2018", "2019", "2020", "2021", "2022"],
      //       flowQty: [1108, 1270, 1309, 1286, 1382, 1567, 1707],
      //     },
      //   },
      //   industrialAddWater_monitor: {
      //     waterTemperature: "23℃",
      //     todayMaxVlue: "2L/min",
      //     currentSpeed: "0.1L/min",
      //     min: {
      //       times: [
      //         "18:04",
      //         "18:05",
      //         "18:06",
      //         "18:07",
      //         "18:08",
      //         "18:09",
      //         "18:10",
      //       ],
      //       flowQty: [0.001, 0.0, 0.001, 0.0011, 0.0007, 0, 0.0005],
      //     },
      //     hour: {
      //       times: ["4:00", "5:00", "6:00", "7:00", "8:00", "9:00", "10:00"],
      //       flowQty: [0.03, 0.032, 0.27, 0.015, 0.07, 0.012, 0.1],
      //     },
      //     day: {
      //       times: ["1/8", "1/9", "1/10", "1/11", "1/12", "1/13", "1/14"],
      //       flowQty: [0.5, 1, 1.7, 2, 1.8, 1.01, 0.8],
      //     },
      //     threeDay: {
      //       times: [
      //         "12/5\n12/27",
      //         "12/28\n12/30",
      //         "12/31\n1/2",
      //         "1/3\n1/5",
      //         "1/6\n1/8",
      //         "1/9\n1/11",
      //         "1/12\n1/14",
      //       ],
      //       flowQty: [2, 1, 4, 5, 3, 2.1, 2.9],
      //     },
      //     week: {
      //       times: [
      //         "11/28\n12/3",
      //         "12/4\n12/10",
      //         "12/11\n12/17",
      //         "12/18\n12/24",
      //         "12/25\n12/31",
      //         "1/1\n1/7",
      //         "1/8\n1/14",
      //       ],
      //       flowQty: [5, 8, 10.6, 8.2, 4.5, 9, 10],
      //     },
      //     month: {
      //       times: ["6月", "7月", "8月", "9月", "10月", "11月", "12月"],
      //       flowQty: [20.6, 29, 31, 56, 42, 30, 28],
      //     },
      //     season: {
      //       times: [
      //         "4-6月",
      //         "7-9月",
      //         "10-12月",
      //         "1-3月",
      //         "4-6月",
      //         "7-9月",
      //         "10-12月",
      //       ],
      //       flowQty: [78, 71, 102.6, 98, 90, 106, 73],
      //     },
      //     year: {
      //       times: ["2016", "2017", "2018", "2019", "2020", "2021", "2022"],
      //       flowQty: [208, 380, 409, 456, 333, 398, 407],
      //     },
      //   },
      //   domesticWater_monitor: {
      //     todayMaxVlue: "9.9L/min",
      //   },
      //   industriaWaterReuseRate: {
      //     day: {
      //       times: ["2/7", "2/8", "2/9", "2/10", "2/11", "2/12"],
      //       flowQty: [89.88, 92, 99.12, 91, 93, 95],
      //     },
      //   },
      // },
      isSupuerUser: true,
      nowTime: "",

      chart_1: null,
      chart_2: null,
      chart_3: null,
      chart_4: null,
      chart_5: null,
      chart_1_radio: "分",
      chart_2_radio: "分",
      chart_3_radio: "分",
      chart_4_radio: "分",
      chart_5_radio: "天",
      chart_1_unit: "吨",
      chart_2_unit: "吨",
      chart_3_unit: "吨",
      chart_4_unit: "吨",
      chart_5_unit: "%",
      option_1: {
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,

          data: ["", "", "", "", "", "", ""],
        },
        yAxis: {
          type: "value",
          min: 0,
          max: 0,
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(255,255,255,0.1)",
            },
          },
          axisLabel: {
            formatter: "{value} 吨",
          },
        },
        series: [
          {
            color: "yellow",
            type: "line",
            data: [0, 0, 0, 0, 0, 0, 0],
            label: { show: true, color: "white", fontSize: 15 },

            // markPoint: {
            //   label: {
            //     show: true,
            //     color: "white",
            //     fontSize: 12,
            //   },
            //   data: [
            //     { type: "max", name: "Max" },
            //     { type: "min", name: "Min" },
            //   ],
            // },
            // markLine: {
            //   label: {
            //     show: true,
            //     color: "yellow",
            //     fontSize: 14,
            //     // formatter(param) {
            //     //   return " " + param.value.toFixed(3);
            //     // },
            //   },
            //   data: [{ type: "average", name: "Avg" }],
            // },
          },
        ],
      },
      option_2: {
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["", "", "", "", "", "", ""],
        },
        yAxis: {
          type: "value",
          min: 0,
          max: 0,
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(255,255,255,0.1)",
            },
          },
          axisLabel: {
            formatter: "{value} 吨",
          },
        },
        series: [
          {
            color: "LightSeaGreen ",
            type: "line",
            data: [0, 0, 0, 0, 0, 0, 0],
            label: { show: true, color: "white", fontSize: 15 },

            // markPoint: {
            //   label: {
            //     show: true,
            //     color: "white",
            //     fontSize: 12,
            //   },
            //   data: [
            //     { type: "max", name: "Max" },
            //     { type: "min", name: "Min" },
            //   ],
            // },
            // markLine: {
            //   label: {
            //     show: true,
            //     color: "LightSeaGreen",
            //     fontSize: 14,
            //     // formatter(param) {
            //     //   return " " + param.value;
            //     // },
            //   },
            //   data: [{ type: "average", name: "Avg" }],
            // },
          },
        ],
      },
      option_3: {
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["", "", "", "", "", "", ""],
        },
        yAxis: {
          type: "value",
          min: 0,
          max: 0,
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(255,255,255,0.1)",
            },
          },
          axisLabel: {
            formatter: "{value} 吨",
          },
        },
        series: [
          {
            color: "MediumVioletRed",
            type: "line",
            data: [0, 0, 0, 0, 0, 0, 0],
            label: { show: true, color: "white", fontSize: 15 },

            // markPoint: {
            //   label: {
            //     show: true,
            //     color: "white",
            //     fontSize: 12,
            //   },
            //   data: [
            //     { type: "max", name: "Max" },
            //     { type: "min", name: "Min" },
            //   ],
            // },
            // markLine: {
            //   label: {
            //     show: true,
            //     color: "MediumVioletRed",
            //     fontSize: 14,
            //     // formatter(param) {
            //     //   return " " + param.value;
            //     // },
            //   },
            //   data: [{ type: "average", name: "Avg" }],
            // },
          },
        ],
      },
      option_4: {
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["", "", "", "", "", "", ""],
        },
        yAxis: {
          type: "value",
          min: 0,
          max: 0,
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(255,255,255,0.1)",
            },
          },
          axisLabel: {
            formatter: "{value} 吨",
          },
        },
        series: [
          {
            color: "red ",
            type: "line",
            data: [0, 0, 0, 0, 0, 0, 0],
            label: { show: true, color: "white", fontSize: 15 },

            // markPoint: {
            //   label: {
            //     show: true,
            //     color: "white",
            //     fontSize: 12,
            //   },
            //   data: [
            //     { type: "max", name: "Max" },
            //     { type: "min", name: "Min" },
            //   ],
            // },
            markLine: {},
          },
        ],
      },
      option_5: {
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["", "", "", "", "", "", ""],
        },
        yAxis: {
          type: "value",
          min: 0,
          max: 0,
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(255,255,255,0.1)",
            },
          },
          axisLabel: {
            formatter: "{value} %",
          },
        },
        series: [
          {
            color: "Lime",
            type: "line",
            data: [0, 0, 0, 0, 0, 0, 0],
            label: { show: true, color: "white", fontSize: 15 },
            // markPoint: {
            //   label: {
            //     show: true,
            //     color: "black",
            //     fontSize: 12,
            //   },
            //   data: [
            //     { type: "max", name: "Max" },
            //     { type: "min", name: "Min" },
            //   ],
            // },
            // markLine: {
            //   label: {
            //     show: true,
            //     color: "Lime",
            //     fontSize: 14,
            //     formatter(param) {
            //       return " " + param.value.toFixed(3);
            //     },
            //   },
            //   data: [{ type: "average", name: "Avg" }],
            // },
          },
        ],
      },
      reportFormPdfs: {},
      reportFormdialogVisible: false,
      singleMonth: Date.now(),
      multiMonth: [Date.now(), Date.now()],
      singleMonthStr: this.dayjs().format("YYYY-MM"),
      multiMonthStr:
        this.dayjs().format("YYYY-MM") + "/" + this.dayjs().format("YYYY-MM"),
      singleBorned: false,
      multiBorned: false,
      singleBornning: false,
      multiBornning: false,
      singleMonthBornedList: [],
      multiMonthBornedList: [],
      pickerOption: {
        disabledDate: (time) => {
          return (
            "2023-01" > this.dayjs(time).format("YYYY-MM") ||
            this.dayjs(time).format("YYYY-MM") > this.dayjs().format("YYYY-MM")
          );
        },
      },
    };
  },

  methods: {
    goLogin() {
      this.tokenErrorDialogVisible = false;
      this.$router.push("/login");
    },
    chart_1_radio_changed(label) {
      // console.log(label);
      // if (label == "分") {
      //   this.chart_1_unit = "升";
      // } else {
      //   this.chart_1_unit = "吨";
      // }
      this.$set(
        this.option_1.yAxis.axisLabel,
        "formatter",
        `{value} ${this.chart_1_unit}`
      );
      let x_axis = [];
      let y_series = [];
      if (label == "分") {
        x_axis = this.resData.tapWater_monitor.min.times;
        y_series = this.resData.tapWater_monitor.min.flowQty;
      } else if (label == "小时") {
        x_axis = this.resData.tapWater_monitor.hour.times;
        y_series = this.resData.tapWater_monitor.hour.flowQty;
      } else if (label == "天") {
        x_axis = this.resData.tapWater_monitor.day.times;
        y_series = this.resData.tapWater_monitor.day.flowQty;
      } else if (label == "三天") {
        x_axis = this.resData.tapWater_monitor.threeDay.times;
        y_series = this.resData.tapWater_monitor.threeDay.flowQty;
      } else if (label == "周") {
        x_axis = this.resData.tapWater_monitor.week.times;
        y_series = this.resData.tapWater_monitor.week.flowQty;
      } else if (label == "月") {
        x_axis = this.resData.tapWater_monitor.month.times;
        y_series = this.resData.tapWater_monitor.month.flowQty;
      } else if (label == "季度") {
        x_axis = this.resData.tapWater_monitor.season.times;
        y_series = this.resData.tapWater_monitor.season.flowQty;
      } else if (label == "年") {
        x_axis = this.resData.tapWater_monitor.year.times;
        y_series = this.resData.tapWater_monitor.year.flowQty;
      }
      // this.option_1.series.data = [99, 77, 80, 90, 99, 68, 86];
      this.$set(this.option_1.xAxis, "data", x_axis);
      this.$set(this.option_1.series[0], "data", y_series);
      this.$set(this.option_1.yAxis, "min", Math.min(...y_series));
      this.$set(this.option_1.yAxis, "max", Math.max(...y_series));
      this.chart_1.setOption(this.option_1);
    },
    chart_2_radio_changed(label) {
      this.$set(
        this.option_2.yAxis.axisLabel,
        "formatter",
        `{value} ${this.chart_2_unit}`
      );
      let x_axis = [];
      let y_series = [];
      if (label == "分") {
        x_axis = this.resData.industrialReuseWater_monitor.min.times;
        y_series = this.resData.industrialReuseWater_monitor.min.flowQty;
      } else if (label == "小时") {
        x_axis = this.resData.industrialReuseWater_monitor.hour.times;
        y_series = this.resData.industrialReuseWater_monitor.hour.flowQty;
      } else if (label == "天") {
        x_axis = this.resData.industrialReuseWater_monitor.day.times;
        y_series = this.resData.industrialReuseWater_monitor.day.flowQty;
      } else if (label == "三天") {
        x_axis = this.resData.industrialReuseWater_monitor.threeDay.times;
        y_series = this.resData.industrialReuseWater_monitor.threeDay.flowQty;
      } else if (label == "周") {
        x_axis = this.resData.industrialReuseWater_monitor.week.times;
        y_series = this.resData.industrialReuseWater_monitor.week.flowQty;
      } else if (label == "月") {
        x_axis = this.resData.industrialReuseWater_monitor.month.times;
        y_series = this.resData.industrialReuseWater_monitor.month.flowQty;
      } else if (label == "季度") {
        x_axis = this.resData.industrialReuseWater_monitor.season.times;
        y_series = this.resData.industrialReuseWater_monitor.season.flowQty;
      } else if (label == "年") {
        x_axis = this.resData.industrialReuseWater_monitor.year.times;
        y_series = this.resData.industrialReuseWater_monitor.year.flowQty;
      }
      // this.option_1.series.data = [99, 77, 80, 90, 99, 68, 86];
      this.$set(this.option_2.xAxis, "data", x_axis);
      this.$set(this.option_2.series[0], "data", y_series);
      // console.log(Math.min(...y_series));
      this.$set(this.option_2.yAxis, "min", Math.min(...y_series));
      this.$set(this.option_2.yAxis, "max", Math.max(...y_series));
      this.chart_2.setOption(this.option_2);
    },
    chart_3_radio_changed(label) {
      // console.log(label);
      // if (label == "分") {
      //   this.chart_3_unit = "升";
      // } else {
      //   this.chart_3_unit = "吨";
      // }
      this.$set(
        this.option_3.yAxis.axisLabel,
        "formatter",
        `{value} ${this.chart_3_unit}`
      );
      let x_axis = [];
      let y_series = [];
      if (label == "分") {
        x_axis = this.resData.industrialAddWater_monitor.min.times;
        y_series = this.resData.industrialAddWater_monitor.min.flowQty;
      } else if (label == "小时") {
        x_axis = this.resData.industrialAddWater_monitor.hour.times;
        y_series = this.resData.industrialAddWater_monitor.hour.flowQty;
      } else if (label == "天") {
        x_axis = this.resData.industrialAddWater_monitor.day.times;
        y_series = this.resData.industrialAddWater_monitor.day.flowQty;
      } else if (label == "三天") {
        x_axis = this.resData.industrialAddWater_monitor.threeDay.times;
        y_series = this.resData.industrialAddWater_monitor.threeDay.flowQty;
      } else if (label == "周") {
        x_axis = this.resData.industrialAddWater_monitor.week.times;
        y_series = this.resData.industrialAddWater_monitor.week.flowQty;
      } else if (label == "月") {
        x_axis = this.resData.industrialAddWater_monitor.month.times;
        y_series = this.resData.industrialAddWater_monitor.month.flowQty;
      } else if (label == "季度") {
        x_axis = this.resData.industrialAddWater_monitor.season.times;
        y_series = this.resData.industrialAddWater_monitor.season.flowQty;
      } else if (label == "年") {
        x_axis = this.resData.industrialAddWater_monitor.year.times;
        y_series = this.resData.industrialAddWater_monitor.year.flowQty;
      }
      // this.option_1.series.data = [99, 77, 80, 90, 99, 68, 86];
      this.$set(this.option_3.xAxis, "data", x_axis);
      this.$set(this.option_3.series[0], "data", y_series);
      // console.log(Math.min(...y_series));
      this.$set(this.option_3.yAxis, "min", Math.min(...y_series));
      this.$set(this.option_3.yAxis, "max", Math.max(...y_series));
      this.chart_3.setOption(this.option_3);
    },
    chart_4_radio_changed(label) {
      this.$set(
        this.option_4.yAxis.axisLabel,
        "formatter",
        `{value} ${this.chart_4_unit}`
      );
      let x_axis = [];
      let y_series = [];
      if (label == "分") {
        x_axis = this.resData.industrialAddWater_monitor.min.times;

        y_series = this.resData.industrialAddWater_monitor.min.flowQty.map(
          (value, index) => {
            let result =
              this.resData.tapWater_monitor.min.flowQty[index] - value;
            return result.toFixed(3);
          }
        );
      } else if (label == "小时") {
        x_axis = this.resData.industrialAddWater_monitor.hour.times;
        y_series = this.resData.industrialAddWater_monitor.hour.flowQty.map(
          (value, index) => {
            let result =
              this.resData.tapWater_monitor.hour.flowQty[index] - value;
            return result.toFixed(2);
          }
        );
      } else if (label == "天") {
        x_axis = this.resData.industrialAddWater_monitor.day.times;
        y_series = this.resData.industrialAddWater_monitor.day.flowQty.map(
          (value, index) => {
            let result =
              this.resData.tapWater_monitor.day.flowQty[index] - value;
            return result.toFixed(2);
          }
        );
      } else if (label == "三天") {
        x_axis = this.resData.industrialAddWater_monitor.threeDay.times;
        y_series = this.resData.industrialAddWater_monitor.threeDay.flowQty.map(
          (value, index) => {
            let result =
              this.resData.tapWater_monitor.threeDay.flowQty[index] - value;
            return result.toFixed(2);
          }
        );
      } else if (label == "周") {
        x_axis = this.resData.industrialAddWater_monitor.week.times;
        y_series = this.resData.industrialAddWater_monitor.week.flowQty.map(
          (value, index) => {
            let result =
              this.resData.tapWater_monitor.week.flowQty[index] - value;
            return result.toFixed(2);
          }
        );
      } else if (label == "月") {
        x_axis = this.resData.industrialAddWater_monitor.month.times;
        y_series = this.resData.industrialAddWater_monitor.month.flowQty.map(
          (value, index) => {
            let result =
              this.resData.tapWater_monitor.month.flowQty[index] - value;
            return result.toFixed(2);
          }
        );
      } else if (label == "季度") {
        x_axis = this.resData.industrialAddWater_monitor.season.times;
        y_series = this.resData.industrialAddWater_monitor.season.flowQty.map(
          (value, index) => {
            let result =
              this.resData.tapWater_monitor.season.flowQty[index] - value;
            return result.toFixed(2);
          }
        );
      } else if (label == "年") {
        x_axis = this.resData.industrialAddWater_monitor.year.times;
        y_series = this.resData.industrialAddWater_monitor.year.flowQty.map(
          (value, index) => {
            let result =
              this.resData.tapWater_monitor.year.flowQty[index] - value;
            return result.toFixed(2);
          }
        );
      }
      // this.option_1.series.data = [99, 77, 80, 90, 99, 68, 86];
      this.$set(this.option_4.xAxis, "data", x_axis);
      this.$set(this.option_4.series[0], "data", y_series);
      // console.log(Math.min(...y_series));
      this.$set(this.option_4.yAxis, "min", Math.min(...y_series));
      this.$set(this.option_4.yAxis, "max", Math.max(...y_series));
      this.chart_4.setOption(this.option_4);
    },
    chart_5_radio_changed(label) {
      this.$set(
        this.option_5.yAxis.axisLabel,
        "formatter",
        `{value} ${this.chart_5_unit}`
      );
      let x_axis = [];
      let y_series = [];
      if (label == "天") {
        x_axis = this.resData.industrialAddWater_monitor.day.times;
        y_series = this.resData.industrialAddWater_monitor.day.flowQty.map(
          (value, index) => {
            let a = parseFloat(
              this.resData.industrialReuseWater_monitor.day.flowQty[index]
            );
            let b = parseFloat(value);
            let result = 100;
            if (a + b == 0) return result;
            result = (a / (a + b)) * 100;
            return result.toFixed(2);
          }
        );
      } else if (label == "三天") {
        x_axis = this.resData.industrialAddWater_monitor.threeDay.times;
        y_series = this.resData.industrialAddWater_monitor.threeDay.flowQty.map(
          (value, index) => {
            let a = parseFloat(
              this.resData.industrialReuseWater_monitor.threeDay.flowQty[index]
            );
            let b = parseFloat(value);
            let result = 100;
            if (a + b == 0) return result;
            result = (a / (a + b)) * 100;
            return result.toFixed(2);
          }
        );
      } else if (label == "周") {
        x_axis = this.resData.industrialAddWater_monitor.week.times;
        y_series = this.resData.industrialAddWater_monitor.week.flowQty.map(
          (value, index) => {
            let a = parseFloat(
              this.resData.industrialReuseWater_monitor.week.flowQty[index]
            );
            let b = parseFloat(value);
            let result = 100;
            if (a + b == 0) return result;
            result = (a / (a + b)) * 100;
            return result.toFixed(2);
          }
        );
      } else if (label == "月") {
        x_axis = this.resData.industrialAddWater_monitor.month.times;
        y_series = this.resData.industrialAddWater_monitor.month.flowQty.map(
          (value, index) => {
            let a = parseFloat(
              this.resData.industrialReuseWater_monitor.month.flowQty[index]
            );
            let b = parseFloat(value);
            let result = 100;
            if (a + b == 0) return result;
            result = (a / (a + b)) * 100;
            return result.toFixed(2);
          }
        );
      } else if (label == "季度") {
        x_axis = this.resData.industrialAddWater_monitor.season.times;
        y_series = this.resData.industrialAddWater_monitor.season.flowQty.map(
          (value, index) => {
            let a = parseFloat(
              this.resData.industrialReuseWater_monitor.season.flowQty[index]
            );
            let b = parseFloat(value);
            let result = 100;
            if (a + b == 0) return result;
            result = (a / (a + b)) * 100;
            return result.toFixed(2);
          }
        );
      } else if (label == "年") {
        x_axis = this.resData.industrialAddWater_monitor.year.times;
        y_series = this.resData.industrialAddWater_monitor.year.flowQty.map(
          (value, index) => {
            let a = parseFloat(
              this.resData.industrialReuseWater_monitor.year.flowQty[index]
            );
            let b = parseFloat(value);
            let result = 100;
            if (a + b == 0) return result;
            result = (a / (a + b)) * 100;
            return result.toFixed(2);
          }
        );
      }
      // console.log(y_series);
      // this.option_1.series.data = [99, 77, 80, 90, 99, 68, 86];
      this.$set(this.option_5.xAxis, "data", x_axis);
      this.$set(this.option_5.series[0], "data", y_series);
      // console.log(Math.min(...y_series));
      this.$set(this.option_5.yAxis, "min", Math.min(...y_series));
      this.$set(this.option_5.yAxis, "max", Math.max(...y_series));
      this.chart_5.setOption(this.option_5);
    },
    setEchart() {
      this.chart_1_radio_changed(this.chart_1_radio);
      this.chart_2_radio_changed(this.chart_2_radio);
      this.chart_3_radio_changed(this.chart_3_radio);
      this.chart_4_radio_changed(this.chart_4_radio);
      this.chart_5_radio_changed(this.chart_5_radio);

      // let x_axis_5 = this.resData.industriaWaterReuseRate.day.times;
      // let y_series_5 = this.resData.industriaWaterReuseRate.day.flowQty.map(
      //   (value) => {
      //     return value.toFixed(1);
      //   }
      // );
      // this.$set(this.option_5.xAxis, "data", x_axis_5);
      // this.$set(this.option_5.series[0], "data", y_series_5);
      // this.$set(this.option_5.yAxis, "min", Math.min(...y_series_5));
      // this.$set(this.option_5.yAxis, "max", Math.max(...y_series_5));
      // this.chart_5.setOption(this.option_5);
    },
    setCompanyData(data) {
      // console.log(data);
      let companyData = this.resData;
      companyData.company_info.companyName = data.companyName;
      companyData.company_info.companyAddress = data.companyAddress;
      companyData.company_info.companyContact = data.companyContact;
      companyData.company_info.companyPhone = data.companyPhone;
      companyData.company_info.companyId = data.companyId;

      let monitorCurrentValue=data.nowActFlowValue//各流量计的当前表面值
      monitorCurrentValue=monitorCurrentValue.split('/')

      let currentValue = data.currentRecord;//各流量计的当前值（加上累加值的）
      currentValue = currentValue.split("/");
      let dayStartValue = data._7day_data[data._7day_data.length - 1];
      dayStartValue = dayStartValue.split("/");

      companyData.todayTotalData.totalTapWater = (
        currentValue[0] - dayStartValue[0]
      ).toFixed(2);
      companyData.todayTotalData.totalIndustrialReuseWater = (
        currentValue[1] - dayStartValue[1]
      ).toFixed(2);
      companyData.todayTotalData.totalIndustrialAddWater = (
        currentValue[2] - dayStartValue[2]
      ).toFixed(2);

      // let minLen = data._7min_data.length;
      let minValue = data._7min_data.map((elem) => {
        let elem_1 = elem.split("/");
        // console.log(elem_1);
        return elem_1.map((e, index) => {
          return (parseFloat(e) * 0.001).toFixed(3);
        });
      });
      // console.log(minValue);

      let hourLen = data._7hour_data.length;
      let hourValue = data._7hour_data.map((elem, index, arr) => {
        let elem_1 = elem.split("/");
        if (index == hourLen - 1) {
          return currentValue.map((e, index) => {
            return (e - elem_1[index]).toFixed(2);
          });
        }
        let elem_2 = arr[index + 1].split("/");
        return elem_2.map((e, index) => {
          return (e - elem_1[index]).toFixed(2);
        });
      });
      // console.log(hourValue);

      let dayLen = data._7day_data.length;
      let dayValue = data._7day_data.map((elem, index, arr) => {
        let elem_1 = elem.split("/");
        if (index == dayLen - 1) {
          return currentValue.map((e, index) => {
            return (e - elem_1[index]).toFixed(2);
          });
        }
        let elem_2 = arr[index + 1].split("/");
        return elem_2.map((e, index) => {
          return (e - elem_1[index]).toFixed(2);
        });
      });
      // console.log(dayValue);

      let threeDayLen = data._7threeDay_data.length;
      let threeDayValue = data._7threeDay_data.map((elem, index, arr) => {
        let elem_1 = elem.split("/");
        if (index == threeDayLen - 1) {
          return currentValue.map((e, index) => {
            return (e - elem_1[index]).toFixed(2);
          });
        }
        let elem_2 = arr[index + 1].split("/");
        return elem_2.map((e, index) => {
          return (e - elem_1[index]).toFixed(2);
        });
      });
      // console.log(threeDayValue);

      let weekLen = data._7week_data.length;
      let weekValue = data._7week_data.map((elem, index, arr) => {
        let elem_1 = elem.split("/");
        if (index == weekLen - 1) {
          return currentValue.map((e, index) => {
            return (e - elem_1[index]).toFixed(2);
          });
        }
        let elem_2 = arr[index + 1].split("/");
        return elem_2.map((e, index) => {
          return (e - elem_1[index]).toFixed(2);
        });
      });
      // console.log(weekValue);

      let monthLen = data._7month_data.length;
      let monthValue = data._7month_data.map((elem, index, arr) => {
        let elem_1 = elem.split("/");
        if (index == monthLen - 1) {
          return currentValue.map((e, index) => {
            return (e - elem_1[index]).toFixed(2);
          });
        }
        let elem_2 = arr[index + 1].split("/");
        return elem_2.map((e, index) => {
          return (e - elem_1[index]).toFixed(2);
        });
      });
      // console.log(monthValue);

      let seasonLen = data._7season_data.length;
      let seasonValue = data._7season_data.map((elem, index, arr) => {
        let elem_1 = elem.split("/");
        if (index == seasonLen - 1) {
          return currentValue.map((e, index) => {
            return (e - elem_1[index]).toFixed(2);
          });
        }
        let elem_2 = arr[index + 1].split("/");
        return elem_2.map((e, index) => {
          return (e - elem_1[index]).toFixed(2);
        });
      });
      // console.log(seasonValue);

      let yearLen = data._7year_data.length;
      let yearValue = data._7year_data.map((elem, index, arr) => {
        let elem_1 = elem.split("/");
        if (index == yearLen - 1) {
          return currentValue.map((e, index) => {
            return (e - elem_1[index]).toFixed(2);
          });
        }
        let elem_2 = arr[index + 1].split("/");
        return elem_2.map((e, index) => {
          return (e - elem_1[index]).toFixed(2);
        });
      });
      // console.log(yearValue);
      data.times.hourTimes = data.times.hourTimes.map((elem) => {
        return elem.replace(":00", "时");
      });
      data.times.dayTimes = data.times.dayTimes.map((elem) => {
        return elem + "日";
      });
      data.times.threeDaytimes = data.times.threeDaytimes.map((elem) => {
        return elem.replace("-", "日\n") + "日";
      });
      data.times.weekTimes = data.times.weekTimes.map((elem) => {
        return elem.replace("-", "日\n") + "日";
      });
      data.times.yearTimes = data.times.yearTimes.map((elem) => {
        return elem + "年";
      });

      //总进水数据处理
      companyData.tapWater_monitor.currentValue=monitorCurrentValue[0]
      companyData.tapWater_monitor.waterTemperature =
        data.tapWater_monitor.waterTemperature + "℃";
      companyData.tapWater_monitor.todayMaxVlue =
        data.tapWater_monitor.todayMaxVlue.toFixed(2) + "L/min";
      companyData.tapWater_monitor.currentSpeed =
        data.tapWater_monitor.currentSpeed.toFixed(2) + "L/min";
      companyData.tapWater_monitor.min.times = data.times.minTimes;
      let tapValue = minValue.map((elem) => {
        return elem[0];
      });
      companyData.tapWater_monitor.min.flowQty = tapValue.slice(
        -1 * data.times.minTimes.length
      );
      companyData.tapWater_monitor.hour.times = data.times.hourTimes;
      tapValue = hourValue.map((elem) => {
        return elem[0];
      });
      companyData.tapWater_monitor.hour.flowQty = tapValue.slice(
        -1 * data.times.hourTimes.length
      );
      companyData.tapWater_monitor.day.times = data.times.dayTimes;
      tapValue = dayValue.map((elem) => {
        return elem[0];
      });
      companyData.tapWater_monitor.day.flowQty = tapValue.slice(
        -1 * data.times.dayTimes.length
      );
      companyData.tapWater_monitor.threeDay.times = data.times.threeDaytimes;
      tapValue = threeDayValue.map((elem) => {
        return elem[0];
      });
      companyData.tapWater_monitor.threeDay.flowQty = tapValue.slice(
        -1 * data.times.threeDaytimes.length
      );
      companyData.tapWater_monitor.week.times = data.times.weekTimes;
      tapValue = weekValue.map((elem) => {
        return elem[0];
      });
      companyData.tapWater_monitor.week.flowQty = tapValue.slice(
        -1 * data.times.weekTimes.length
      );
      companyData.tapWater_monitor.month.times = data.times.monthTimes;
      tapValue = monthValue.map((elem) => {
        return elem[0];
      });
      companyData.tapWater_monitor.month.flowQty = tapValue.slice(
        -1 * data.times.monthTimes.length
      );
      companyData.tapWater_monitor.season.times = data.times.seasonTimes;
      tapValue = seasonValue.map((elem) => {
        return elem[0];
      });
      companyData.tapWater_monitor.season.flowQty = tapValue.slice(
        -1 * data.times.seasonTimes.length
      );
      companyData.tapWater_monitor.year.times = data.times.yearTimes;
      tapValue = yearValue.map((elem) => {
        return elem[0];
      });
      companyData.tapWater_monitor.year.flowQty = tapValue.slice(
        -1 * data.times.yearTimes.length
      );

      //工业回用水数据处理
      companyData.industrialReuseWater_monitor.currentValue=monitorCurrentValue[1]
      companyData.industrialReuseWater_monitor.waterTemperature =
        data.industrialReuseWater_monitor.waterTemperature + "℃";
      companyData.industrialReuseWater_monitor.todayMaxVlue =
        data.industrialReuseWater_monitor.todayMaxVlue.toFixed(2) + "L/min";
      companyData.industrialReuseWater_monitor.currentSpeed =
        data.industrialReuseWater_monitor.currentSpeed.toFixed(2) + "L/min";
      companyData.industrialReuseWater_monitor.min.times = data.times.minTimes;
      tapValue = minValue.map((elem) => {
        return elem[1];
      });
      companyData.industrialReuseWater_monitor.min.flowQty = tapValue.slice(
        -1 * data.times.minTimes.length
      );
      companyData.industrialReuseWater_monitor.hour.times =
        data.times.hourTimes;
      tapValue = hourValue.map((elem) => {
        return elem[1];
      });
      companyData.industrialReuseWater_monitor.hour.flowQty = tapValue.slice(
        -1 * data.times.hourTimes.length
      );
      companyData.industrialReuseWater_monitor.day.times = data.times.dayTimes;
      tapValue = dayValue.map((elem) => {
        return elem[1];
      });
      companyData.industrialReuseWater_monitor.day.flowQty = tapValue.slice(
        -1 * data.times.dayTimes.length
      );
      companyData.industrialReuseWater_monitor.threeDay.times =
        data.times.threeDaytimes;
      tapValue = threeDayValue.map((elem) => {
        return elem[1];
      });
      companyData.industrialReuseWater_monitor.threeDay.flowQty =
        tapValue.slice(-1 * data.times.threeDaytimes.length);
      companyData.industrialReuseWater_monitor.week.times =
        data.times.weekTimes;
      tapValue = weekValue.map((elem) => {
        return elem[1];
      });
      companyData.industrialReuseWater_monitor.week.flowQty = tapValue.slice(
        -1 * data.times.weekTimes.length
      );
      companyData.industrialReuseWater_monitor.month.times =
        data.times.monthTimes;
      tapValue = monthValue.map((elem) => {
        return elem[1];
      });
      companyData.industrialReuseWater_monitor.month.flowQty = tapValue.slice(
        -1 * data.times.monthTimes.length
      );
      companyData.industrialReuseWater_monitor.season.times =
        data.times.seasonTimes;
      tapValue = seasonValue.map((elem) => {
        return elem[1];
      });
      companyData.industrialReuseWater_monitor.season.flowQty = tapValue.slice(
        -1 * data.times.seasonTimes.length
      );
      companyData.industrialReuseWater_monitor.year.times =
        data.times.yearTimes;
      tapValue = yearValue.map((elem) => {
        return elem[1];
      });
      companyData.industrialReuseWater_monitor.year.flowQty = tapValue.slice(
        -1 * data.times.yearTimes.length
      );

      //工业补水数据处理
      companyData.industrialAddWater_monitor.currentValue=monitorCurrentValue[2]
      companyData.industrialAddWater_monitor.waterTemperature =
        data.industrialAddWater_monitor.waterTemperature + "℃";
      companyData.industrialAddWater_monitor.todayMaxVlue =
        data.industrialAddWater_monitor.todayMaxVlue.toFixed(2) + "L/min";
      companyData.industrialAddWater_monitor.currentSpeed =
        data.industrialAddWater_monitor.currentSpeed.toFixed(2) + "L/min";
      companyData.industrialAddWater_monitor.min.times = data.times.minTimes;
      tapValue = minValue.map((elem) => {
        return elem[2];
      });
      companyData.industrialAddWater_monitor.min.flowQty = tapValue.slice(
        -1 * data.times.minTimes.length
      );
      companyData.industrialAddWater_monitor.hour.times = data.times.hourTimes;
      tapValue = hourValue.map((elem) => {
        return elem[2];
      });
      companyData.industrialAddWater_monitor.hour.flowQty = tapValue.slice(
        -1 * data.times.hourTimes.length
      );
      companyData.industrialAddWater_monitor.day.times = data.times.dayTimes;
      tapValue = dayValue.map((elem) => {
        return elem[2];
      });
      companyData.industrialAddWater_monitor.day.flowQty = tapValue.slice(
        -1 * data.times.dayTimes.length
      );
      companyData.industrialAddWater_monitor.threeDay.times =
        data.times.threeDaytimes;
      tapValue = threeDayValue.map((elem) => {
        return elem[2];
      });
      companyData.industrialAddWater_monitor.threeDay.flowQty = tapValue.slice(
        -1 * data.times.threeDaytimes.length
      );
      companyData.industrialAddWater_monitor.week.times = data.times.weekTimes;
      tapValue = weekValue.map((elem) => {
        return elem[2];
      });
      companyData.industrialAddWater_monitor.week.flowQty = tapValue.slice(
        -1 * data.times.weekTimes.length
      );
      companyData.industrialAddWater_monitor.month.times =
        data.times.monthTimes;
      tapValue = monthValue.map((elem) => {
        return elem[2];
      });
      companyData.industrialAddWater_monitor.month.flowQty = tapValue.slice(
        -1 * data.times.monthTimes.length
      );
      companyData.industrialAddWater_monitor.season.times =
        data.times.seasonTimes;
      tapValue = seasonValue.map((elem) => {
        return elem[2];
      });
      companyData.industrialAddWater_monitor.season.flowQty = tapValue.slice(
        -1 * data.times.seasonTimes.length
      );
      companyData.industrialAddWater_monitor.year.times = data.times.yearTimes;
      tapValue = yearValue.map((elem) => {
        return elem[2];
      });
      companyData.industrialAddWater_monitor.year.flowQty = tapValue.slice(
        -1 * data.times.yearTimes.length
      );

      //生活用水数据处理
      companyData.domesticWater_monitor.todayMaxVlue =
        data.domesticWater_monitor.todayMaxVlue.toFixed(2) + "L/min";

      //工业用水回用率 数据处理
      // companyData.industriaWaterReuseRate.day.flowQty =
      //   data._6day_warterReuse.map((elem) => {
      //     return parseFloat(elem);
      //   });
      // companyData.industriaWaterReuseRate.day.times = data.times.dayTimes.slice(
      //   data.times.dayTimes.length - 1 - data._6day_warterReuse.length,
      //   data._6day_warterReuse.length
      // );

      this.setEchart();
    },
    backToHome() {
      this.$router.back();
    },
    bornReportForm() {
      this.reportFormdialogVisible = true;
    },
    singleMonthChange() {
      // console.log(this.singleMonthStr);
      // console.log(this.singleMonth);
      if (!this.singleMonth) return;
      this.singleMonthStr = this.dayjs(this.singleMonth).format("YYYY-MM");
      // console.log(this.singleMonthBornedList);
      if (this.singleMonthBornedList.indexOf(this.singleMonthStr) >= 0) {
        this.singleBorned = true;
        this.singleBornning = true;
      } else {
        this.singleBorned = false;
        this.singleBornning = false;
      }

      // console.log(this.singleMonthStr);
    },
    multiMonthChange() {
      // console.log(this.multiMonthStr);
      // console.log(this.multiMonth);
      if (!this.multiMonth) return;
      this.multiMonthStr =
        this.dayjs(this.multiMonth[0]).format("YYYY-MM") +
        "/" +
        this.dayjs(this.multiMonth[1]).format("YYYY-MM");
      // console.log(this.multiMonthBornedList);
      if (this.multiMonthBornedList.indexOf(this.multiMonthStr) >= 0) {
        this.multiBorned = true;
        this.multiBornning = true;
      } else {
        this.multiBorned = false;
        this.multiBornning = false;
      }

      // console.log(this.multiMonthStr);
    },
    async singleMonthBorn() {
      this.singleBornning = true;
      // console.log(this.resData.company_info.companyId, this.singleMonthStr);
      const res = await this.$http.post(
        "api/Cobweb.MES.IMESAPI/ApiService/ExportFlowRpt",
        {
          companyId: this.resData.company_info.companyId,
          month: this.singleMonthStr,
        }
      );
      // console.log(res);
      if (res.data.Status != 200) {
        this.singleBornning = false;
        return this.$message.error(res.data.Message);
      }
      this.$message.success("报表生成成功！");
      this.singleBorned = true;
      this.singleMonthBornedList.push(this.singleMonthStr);
      this.reportFormPdfs[this.singleMonthStr] = res.data.Data;
      // console.log(this.reportFormPdfs);
    },
    async multiMonthBorn() {
      this.multiBornning = true;
      const res = await this.$http.post(
        "api/Cobweb.MES.IMESAPI/ApiService/ExportFlowRpt",
        {
          companyId: this.resData.company_info.companyId,
          month: this.multiMonthStr,
        }
      );
      if (res.data.Status != 200) {
        this.multiBornning = false;
        return this.$message.error(res.data.Message);
      }
      this.$message.success("报表生成成功！");
      this.multiBorned = true;
      this.multiMonthBornedList.push(this.multiMonthStr);
      this.reportFormPdfs[this.multiMonthStr] = res.data.Data;
    },
    downloadRportForm(sOrm) {
      if (sOrm == "s") {
        window.open(this.reportFormPdfs[this.singleMonthStr]);
      } else if (sOrm == "m") {
        window.open(this.reportFormPdfs[this.multiMonthStr]);
      }
    },
    reportFormDialogClose() {
      this.singleMonthBornedList = [];
      this.multiMonthBornedList = [];
      this.singleBorned = false;
      this.multiBorned = false;
      this.singleBornning = false;
      this.multiBornning = false;
    },
  },
  computed: {
    totalDomesticWater() {
      let x = this.resData.todayTotalData.totalTapWater;
      let y = this.resData.todayTotalData.totalIndustrialAddWater;

      return (x - y).toFixed(2);
    },
  },
  created() {
    this.companyId = this.$route.query.companyId;
    if (this.companyId.charAt(this.companyId.length - 1) == "$") {
      this.isSupuerUser = false;
      this.companyId = this.companyId.substring(0, this.companyId.length - 1);
    }
    // console.log(this.companyId);
  },
  async mounted() {
    this.chart_1 = echarts.init(document.getElementById("chart_1"));
    this.chart_2 = echarts.init(document.getElementById("chart_2"));
    this.chart_3 = echarts.init(document.getElementById("chart_3"));
    this.chart_4 = echarts.init(document.getElementById("chart_4"));
    this.chart_5 = echarts.init(document.getElementById("chart_5"));
    this.setEchart();

    this.nowTime = this.dayjs().format("YYYY-MM-DD HH:mm:ss");
    this.time = setInterval(() => {
      this.nowTime = this.dayjs().format("YYYY-MM-DD HH:mm:ss");
    }, 1000);
    //监听浏览器尺寸变化的参考代码
    // window.addEventListener("resize", () => {
    //    this.chartLine.resize();
    //  });
    // console.log(this.companyId);

    // return;

    const res = await this.$http.get(
      "api/Cobweb.MES.IMESAPI/ApiService/GetFlowWaterData",
      {
        params: {
          companyId: this.companyId,
        },
      }
    );
    if (res.data.Status != 200) {
      if (res.data.Status == 400) {
        if (res.data.Data.tokenIsWrong == true) {
          this.tokenErrorDialogVisible = true;
          return
        }
      }
      return this.$message.error(res.data.Message);
    }
    // console.log(res);
    this.setCompanyData(res.data.Data);

    this.getDataInter = setInterval(async () => {
      const res = await this.$http.get(
        "api/Cobweb.MES.IMESAPI/ApiService/GetFlowWaterData",
        {
          params: {
            companyId: this.companyId,
          },
        }
      );
      if (res.data.Status != 200) {
        if (res.data.Status == 400) {
        if (res.data.Data.tokenIsWrong == true) {
          this.tokenErrorDialogVisible = true;
          return
        }
      }
        return this.$message.error(res.data.Message);
      }
      this.setCompanyData(res.data.Data);
    }, 60000);
  },
  beforeDestroy() {
    if (this.time) {
      clearInterval(this.time);
    }
    if (this.getDataInter) {
      clearInterval(this.getDataInter);
    }
  },
};
</script>

<style scoped>
.company-overview {
  display: flex;
}
.company_info {
  padding-right: 10px;
  flex-basis: 50%;
  font-size: 1.05em;
}
.company_info_item {
  display: flex;
  margin: 5px 0;
}
.company_info_item > div:first-child {
  width: 110px;
  flex-shrink: 0;
  /* background-color: red; */
}
.today-total-data {
  margin-top: 5px;
  padding: 10px 20px;
  flex-basis: 50%;
  font-size: 1.1em;
  text-align: center;
  /* background-color: rgba(64,158,255,0.1); */
  line-height: 1.8em;
  transform: translate(0, -55px);
}
.date-time {
  /* position: absolute;
  top: 0;
  right: 0; */
  /* vertical-align: middle; */
  /* margin-left: 15px; */
  font-size: 0.8em;
  /* padding-bottom: 0.2em; */
  color: #ccc;
  letter-spacing: 0;
}
.today-total-data .total-item {
  margin: 5px 5px;
  padding: 5px 0;
  border: solid 1px rgba(255, 255, 255, 0.1);
}
.total-item-name {
  color: darkorange;
}
.total-item-value {
  color: crimson;
}
.monitor-card {
  height: 308px;
  margin-bottom: 10px;
  background-color: #100c2a;
  color: #fff;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5) !important;
  /* border: 1px solid rgba(255, 255, 255, 0.8); */
}
.monitor-card.bottom-card {
  margin-bottom: 0;
}
.monitor-card.simple_card {
  height: 270px;
}
.title_area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.1em;
  padding: 0 20px 10px 5px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.explain {
  opacity: 0.6;
  font-size: 0.75em;
}
.chart_area {
  /* padding-right: 50px; */
  /* overflow:visible; */
  height: 235px;
  width: 100%;
  display: flex;
  /* box-sizing: border-box; */
  /* align-items: stretch; */
}
.other_monitor {
  /* background-color: red; */
  width: 90px;
  height: 215px;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.other_monitor > div {
  line-height: 25px;
}
.other_monitor > div:nth-child(2n + 1) {
  color: DarkOrange;
}
.other_monitor > div:nth-child(2n) {
  color: Crimson;
  font-size: 0.95em;
  margin-bottom: 5px;
}
.chart_monitor {
  /* margin-right: 50px; */
  flex-basis: 50%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.echart_box {
  /* width: 600px; */
  width: 100%;
  height: 265px;
  flex-shrink: 0;
  /* background-color: #fff; */
  transform: translate(0, -10px);
}
.echart_box.reuse_chart {
  /* width: 600px; */
  transform: translate(0, -25px);
  height: 235px;
}
.btns_group {
  height: 30px;
  transform: translate(0, -45px);
}
.btns_group.btns_reuse {
  transform: translate(0, -60px);
}
.red-font {
  color: crimson;
}
.yellow-font {
  color: yellow;
}
.date-picker-container {
  margin: 0 0 15px 0;
}
</style>