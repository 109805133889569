<template>
  <div class="login_container">
    <div class="login_box">
      <div class="company-title">游卡互动数据系统</div>

      <div class="avatar_box">
        <img src="../assets/logo.png" alt="" />
      </div>
      <el-form
        ref="loginFormRef"
        :model="loginForm"
        :rules="loginFormRules"
        label-width="68px"
        class="login_form"
      >
        <el-form-item label="用户名" prop="userName">
          <el-input
            prefix-icon="el-icon-user-solid"
            v-model="loginForm.userName"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input
            type="password"
            prefix-icon="el-icon-lock"
            v-model="loginForm.password"
          ></el-input>
        </el-form-item>
        <el-form-item class="btns">
          <el-button type="primary" @click="login" :loading='userIsLoading' :disabled='userIsLoading'>登录</el-button>
          <el-button type="info" @click="resetloginForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="icp-footer">
      <a href="https://beian.miit.gov.cn/#/Integrated/index"
        >粤ICP备2023007851号</a
      >
    </div>

    <el-dialog
      title="修改密码提示"
      :visible.sync="dialogVisible"
      width="50%"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <span>您使用的还是初始密码，请修改登录密码！</span>
      <br />
      <span>新密码不少于8位，要求包含数字和字母。</span>
      <el-form
        ref="newPasswordFormRef"
        :model="newPasswordForm"
        :rules="newPasswordFormRules"
        label-width="68px"
        style="margin-top: 20px"
      >
        <el-form-item label="新密码" prop="newPassword_1">
          <el-input
            v-model="newPasswordForm.newPassword_1"
            type="password"
            placeholder="请输入新密码"
            class="light-bg"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword_2">
          <el-input
            v-model="newPasswordForm.newPassword_2"
            type="password"
            placeholder="请再次输入新密码"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="gotoHome">取 消</el-button>
        <el-button type="primary" @click="changePassword">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    let checkPwd = (rule, value, callback) => {
      if (value.trim().length == 0) {
        callback(new Error("请输入确认密码"));
      } else if (value != this.newPasswordForm.newPassword_1) {
        callback(new Error("两次密码不一致"));
      } else {
        callback();
      }
    };
    return {
      userIsLoading:false,
      loginForm: {
        userName: "",
        password: "",
      },
      loginFormRules: {
        userName: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
          { min: 6, message: "密码长度不能少于6个字符", trigger: "blur" },
        ],
      },

      dialogVisible: false,
      newPasswordForm: {
        newPassword_1: "",
        newPassword_2: "",
      },
      newPasswordFormRules: {
        newPassword_1: [
          {
            required: true,
            message: "请输入新密码",
            trigger: "blur",
          },
          {
            pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
            message: "至少8个字符，至少一个字母和一个数字",
            trigger: "blur",
          },
        ],
        newPassword_2: [
          {
            validator: checkPwd,
            required: true,
            trigger: "blur",
          },
        ],
      },
      isRegionUser: false,
      regionCode: "",
      companys: [],
    };
  },
  methods: {
    resetloginForm() {
      this.$refs.loginFormRef.resetFields();
    },
    gotoHome() {
      //通过编程式导航跳转到后台主页
      if (this.isRegionUser) {
        this.$router.push({
          path: "/home",
          query: { regionCode: this.regionCode,type:'region' },
        });
      } else {
        if(this.companys.length<2){
          this.$router.push({path:"/companyhome",query:{companyId:this.companys[0]+'$'}})
        }else{
          this.$router.push({path:"/home",query:{companys:JSON.stringify(this.companys),type:'company' }})
        }
      }
    },
    changePassword() {
      this.$refs.newPasswordFormRef.validate(async (valid) => {
        if (!valid) return;
        let path = this.isRegionUser
          ? "api/Cobweb.MES.IMESAPI/ApiService/UpdateRegionPassword"
          : "api/Cobweb.MES.IMESAPI/ApiService/UpdatePassword";
        // console.log(path);
        const res = await this.$http.post(path, {
          password: this.newPasswordForm.newPassword_1,
          userName: this.loginForm.userName,
        });
        if (res.data.Status != 200) {
          return this.$message.error(res.data.Message);
        }
        this.$message.success("密码修改成功！");

        // console.log(res);
        this.gotoHome();
      });
    },

    login() {
      this.$refs.loginFormRef.validate(async (valid) => {
        if (!valid) return;

        //用服务器发出post请求，请求地址为请求根路径+login，登录请求的结果放在res中。
        // const res = await this.$http.post("login", this.loginForm);
        // console.log(this.userName, this.password);
        // if (
        //   this.loginForm.userName != "ceshi" ||
        //   this.loginForm.password != "123456"
        // )return this.$message.error("用户名或密码错误！");
        // console.log(this.loginForm);
        // console.log(this.loginForm);
        this.userIsLoading=true

        const res = await this.$http.post(
          "api/Cobweb.MES.IMESAPI/ApiService/UserLogin",
          this.loginForm
        );
        this.userIsLoading=false

        // console.log(res);
        if (res.data.Status != 200) {
          return this.$message.error(res.data.Message);
        }
        this.$message.success("登录成功！");

        // 1. 将登录成功之后的 token，保存到客户端的 sessionStorage 中
        //   1.1 项目中除了登录之外的其他API接口，必须在登录之后才能访问
        //   1.2 token 只应在当前网站打开期间生效，所以将 token 保存在 sessionStorage 中
        window.sessionStorage.setItem("token", res.data.Data.token);

        let needChange = false;
        if (this.loginForm.userName.match(/^[0-9]/)) {
          this.isRegionUser = false;
          this.companys = res.data.Data.companys;
          let len = this.loginForm.userName.length;
          if (
            this.loginForm.password ==
            this.loginForm.userName.slice(len - 4, len) + "abcd"
          ) {
            needChange = true;
          }
          
          // console.log(this.companys);
        } else {
          this.isRegionUser = true;
          this.regionCode = res.data.Data.regionCode;
          if (
            this.loginForm.password ==
            this.regionCode.toLocaleLowerCase() + "abcd"
          ) {
            needChange = true;
          }
          
          // console.log(this.regionCode);
        }
        // console.log(needChange);
        if (needChange) {
          this.dialogVisible = true;
        } else {
          this.gotoHome();
        }
      });
    },
  },
};
</script>

<style scoped>
.login_container {
  /* background-color: #2b4b6b; */
  height: 100%;
}

.login_box {
  width: 450px;
  height: 300px;
  background-color: #fff;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.company-title {
  position: absolute;
  /* background-color: #000; */
  width: 100%;
  top: -280px;
  text-align: center;
  font-size: 3em;
  color: wheat;
  letter-spacing: 5px;
  margin: 100px auto;
}
.avatar_box {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  padding: 5px;
  background-color: #eee;
  box-shadow: 0 3px 6px -2px rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}
.avatar_box img {
  width: 100%;
  height: 100%;
  background-color: #eee;
  border-radius: 50%;
}
.login_form {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 20px 0 12px;
  box-sizing: border-box;
}
.btns {
  display: flex;
  justify-content: flex-end;
}
.icp-footer {
  /* text-align: center; */
  /* margin: 0 auto; */
  position: fixed;
  bottom: 50px;
  left: 50%;
  transform: translate(-50%, 0);
}
.icp-footer > a {
  color: black;
}
</style>