import Vue from 'vue'
import {
    Button,
    Form,
    FormItem,
    Input,
    Message,
    Card,
    Row,
    Col,
    RadioGroup,
    RadioButton,
    Select,
    Option,
    Table,
    TableColumn,
    MessageBox,
    Tag,
    Dialog,
    Cascader,
    DatePicker,
    Divider,
    Drawer,
    Tabs,
    TabPane,
    Pagination,
    Loading
} from 'element-ui'

Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Card)
Vue.use(Row)
Vue.use(Col)
Vue.use(RadioGroup)
Vue.use(RadioButton)
Vue.use(Select)
Vue.use(Option)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Tag)
Vue.use(Dialog)
Vue.use(Cascader)
Vue.use(DatePicker)
Vue.use(Divider)
Vue.use(Drawer)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Pagination)
Vue.use(Loading)
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$message = Message